import 'react-image-crop/dist/ReactCrop.css';

import { Field, Form, Formik, FormikProps, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Mui5TextField } from '@/App/Shared/Form/Components/Formik/mui5/Mui5TextField';
import { Button, InfoMessage, LineDivider } from '@/Components';
import { useEventsModalHelper } from '@/Hooks/useEventsModalHelper';
import { EventsService } from '@/Services';
import { EventList, StreamSettings, UpdateEventTypes } from '@/Types';
import { EditEventStreamValidation } from '@/Utils';

import { TextFieldsContainer } from './EditEventsStream.styles';
import { EventsModal } from './EventsModal';
import { ButtonContainer } from './EventsModal.styles';

type EditEventStreamProps = {
  eventIds: string[];
  eventsService: EventsService;
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (value: UpdateEventTypes) => void;
  handleCancel: (eventIds: string[]) => void;
};

export const EditEventsStream = ({
  eventIds,
  isOpen,
  handleClose,
  handleSubmit,
  eventsService,
  handleCancel,
}: EditEventStreamProps) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { values, validateForm } = useFormikContext<EventList>();
  const { selectedEvents, subHeader, hasPublishedEvents } = useEventsModalHelper(
    eventIds,
    values.eventsData.result,
  );
  const [initialFormData, setInitialFormData] = useState<StreamSettings>();

  const handleWebsiteChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, formik: FormikProps<StreamSettings>) => {
      const updatedString = e.currentTarget.value
        .split(' ')
        .join('')
        .replace('https://', '')
        .replace('http://', '');
      formik.setFieldValue('streamLink', updatedString);
    },
    [],
  );

  const handleCancelEvent = () => {
    handleCancel(eventIds);
    handleClose();
  };

  useEffect(() => {
    if (!initialFormData) {
      if (selectedEvents.length === 1) {
        const event = selectedEvents[0];
        setInitialFormData({
          streamingHost: event.streamingHost ? event.streamingHost : '',
          streamLink: event.streamLink
            ? event.streamLink.replace('http://', '').replace('https://', '')
            : '',
          streamPassword: event.streamPassword ? event.streamPassword : '',
          meetingId: event.meetingId ? event.meetingId.toString() : '',
          additionalInformation: event.additionalInformation ? event.additionalInformation : '',
        });
      } else if (selectedEvents.length > 1) {
        setInitialFormData({
          streamingHost: '',
          streamLink: '',
          streamPassword: '',
          meetingId: '',
          additionalInformation: '',
        });
      }
    }
  }, [initialFormData, values.eventsData.result, eventIds, selectedEvents]);

  const handleSubmitForm = (values: StreamSettings) => {
    const valuesForUpdate = eventsService.getUpdatableStreamingEventValues(values);
    const updatedData = { ...valuesForUpdate };
    if (updatedData.streamLink) {
      updatedData.streamLink = 'https://' + updatedData.streamLink;
    }
    handleSubmit(updatedData);
    handleClose();
  };

  // Trigger validation on language change
  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl.locale]); // Run when the language changes

  return (
    <EventsModal
      open={isOpen}
      onClose={handleClose}
      header={formatMessage({
        id: 'view.courses_and_events.events.dialog.edit_stream.header',
        defaultMessage: 'Edit streaming data / replace link',
      })}
      subHeader={subHeader}>
      <>
        {selectedEvents.length > 0 && initialFormData && (
          <Formik
            validateOnChange
            validationSchema={() => EditEventStreamValidation(intl)}
            initialValues={initialFormData}
            onSubmit={handleSubmitForm}>
            {formik => (
              <Form>
                <TextFieldsContainer>
                  <Field
                    component={Mui5TextField}
                    variant='outlined'
                    name='streamLink'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleWebsiteChange(e, formik)
                    }
                    label={formatMessage({
                      id: 'form.edit_stream.field.link.label',
                      defaultMessage: 'Access link to the live stream*',
                    })}
                  />

                  <Field
                    type='text'
                    component={Mui5TextField}
                    variant='outlined'
                    name='streamPassword'
                    label={formatMessage({
                      id: 'form.edit_stream.field.password.label',
                      defaultMessage: 'Password',
                    })}
                  />

                  <Field
                    type='text'
                    component={Mui5TextField}
                    variant='outlined'
                    name='meetingId'
                    label={formatMessage({
                      id: 'form.edit_stream.field.meeting_id.label',
                      defaultMessage: 'Meeting ID',
                    })}
                  />

                  <Field
                    component={Mui5TextField}
                    multiline
                    rows={4}
                    variant='outlined'
                    name='additionalInformation'
                    type='text'
                    label={formatMessage({
                      id: 'form.edit_stream.field.additional_information.label',
                      defaultMessage: 'Additional information',
                    })}
                    placeholder={formatMessage({
                      id: 'form.edit_stream.field.additional_information.label',
                      defaultMessage: 'Additional information',
                    })}
                  />
                </TextFieldsContainer>

                {hasPublishedEvents && (
                  <InfoMessage
                    sxContainer={{ mt: 3 }}
                    title={formatMessage({
                      id: 'view.courses_and_events.events.dialog.edit_event.hint.cancel_event.header',
                      defaultMessage: 'Existing registrations will be canceled',
                    })}
                    description={formatMessage({
                      id: 'view.courses_and_events.events.dialog.edit_event.hint.cancel_event.description',
                      defaultMessage:
                        'If registrations for this date have already been received, Hansefit members will be informed that the date will not take place.',
                    })}
                  />
                )}

                {!hasPublishedEvents && <LineDivider verticalSpace={45} />}

                <ButtonContainer>
                  {hasPublishedEvents ? (
                    <Button variant='outlined' onClick={handleCancelEvent} textTransform>
                      {formatMessage({
                        id: 'view.courses_and_events.events.dialog.edit_event.cta.cancel',
                        defaultMessage: 'Cancel appointment',
                      })}
                    </Button>
                  ) : (
                    <LineDivider horizontalSpace='inherit' />
                  )}

                  <Button type='submit' disabled={!formik.isValid} textTransform>
                    {hasPublishedEvents
                      ? formatMessage({
                          id: 'cta.save_and_publish',
                          defaultMessage: 'Save & Publish',
                        })
                      : formatMessage({
                          id: 'cta.save_changes',
                          defaultMessage: 'Save changes',
                        })}
                  </Button>
                </ButtonContainer>
              </Form>
            )}
          </Formik>
        )}
      </>
    </EventsModal>
  );
};
