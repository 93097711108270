import { Auth } from 'aws-amplify';
import { Dispatch } from 'react';

import { AnyStateAction } from '@/Context';
import { HttpService, PartnerCoursesService } from '@/Services';

export class PartnerCoursesServiceFactory {
  public getInstance(dispatch: Dispatch<AnyStateAction>): PartnerCoursesService {
    return new PartnerCoursesService(new HttpService(Auth, dispatch));
  }
}
