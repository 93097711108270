import { Box } from '@mui/material';
import { GridColDef, GridColumnsState } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { TranslationService } from '@/App/Shared/Translation/TranslationService';
import { Button, LineDivider } from '@/Components';
import { useEventsModalHelper } from '@/Hooks/useEventsModalHelper';
import { useGetPartnerQuery } from '@/Queries';
import { EventsService } from '@/Services';
import { Booking, EventListItem } from '@/Types';
import exportHelper from '@/Utils/exportHelper';

import { Company, Item, Name, useBookingsStyles } from './Bookings.styles';
import { EventsModal } from './EventsModal';
import { ButtonContainer } from './EventsModal.styles';

type BookingsListProps = {
  isOpen: boolean;
  eventIds: string[];
  handleClose: () => void;
  events: EventListItem[];
  eventsService: EventsService;
};
export const Bookings = ({
  isOpen,
  handleClose,
  events,
  eventsService,
  eventIds,
}: BookingsListProps) => {
  const { formatMessage } = useIntl();
  const { data: partnerData } = useGetPartnerQuery();

  const { classes } = useBookingsStyles();
  const event = events[0];
  const { subHeader } = useEventsModalHelper(eventIds, events);
  const [bookings, setBookings] = useState<Booking[]>([]);

  const header = useMemo(
    () =>
      TranslationService.replacePlaceholder(
        formatMessage({
          id: 'view.courses_and_events.events.dialog.bookings_list.header.bookings',
          defaultMessage: 'Registration list {{courseName}}',
        }),
        '{{courseName}}',
        event.courseName,
      ),
    [event.courseName, formatMessage],
  );

  const handleExport = useCallback(() => {
    const firstNameLabel = formatMessage({
      id: 'view.statistic.check_in_all.table.header.first_name',
      defaultMessage: 'First name',
    });
    const lastNameLabel = formatMessage({
      id: 'view.statistic.check_in_all.table.header.last_name',
      defaultMessage: 'Last name',
    });
    const companyNameLabel = formatMessage({
      id: 'view.statistic.check_in_all.table.header.company_name',
      defaultMessage: 'Company name',
    });

    const data = bookings.map(booking => ({
      [firstNameLabel]: booking.firstname,
      [lastNameLabel]: booking.lastname,
      [companyNameLabel]: booking.company,
    }));

    exportHelper(
      data,
      Object.keys(data[0]).map(name => ({
        headerName: name,
        field: name,
      })) as GridColDef[],
      {} as GridColumnsState,
      'PDF',
    );
  }, [bookings, formatMessage]);

  const getBookings = useCallback(async () => {
    if (!partnerData) return;

    const response = await eventsService.getBookings(
      partnerData.casPublicId,
      event.courseId,
      event.eventId.toString(),
    );

    setBookings(response.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, partnerData]);

  useEffect(() => {
    getBookings();
  }, [getBookings]);

  return (
    <EventsModal open={isOpen} onClose={handleClose} header={header} subHeader={subHeader}>
      {bookings.length > 0 && (
        <Box className={classes.bookingsContainer}>
          {bookings.map(booking => (
            <Item key={booking.lastname}>
              <Name variant='body2'>{`${booking.firstname} ${booking.lastname}`}</Name>
              <Company variant='body2'>{`${booking?.company || 'N/A'}`}</Company>
            </Item>
          ))}
        </Box>
      )}

      <ButtonContainer>
        <LineDivider horizontalSpace='inherit' />
        <Button onClick={handleExport}>
          {formatMessage({
            id: 'view.courses_and_events.events.dialog.bookings_list.cta.export_bookings',
            defaultMessage: 'Export login list',
          })}
        </Button>
      </ButtonContainer>
    </EventsModal>
  );
};
