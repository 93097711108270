import { Autocomplete, Box } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useCallback, useMemo, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import { OpeningHourMap } from '@/Mappers/OpeningHour';
import { ReactComponent as Arrow } from '@/Static/Icons/atoms-symbols-arrow-light.svg';
import { OpeningHour } from '@/Types';

import { CustomizedTextField } from './CustomizedTextField';

type CustomizedAutocompleteHoursProps = {
  threshold: string;
  direction: 'from' | 'to';
  openingHourService: OpeningHourMap;
  label: string;
  handleRangeChange: (value: string, idx: number, openingHourIdx: number) => void;
  timeIdx: number;
  idx: number;
  value: string;
  disabled: boolean;
};

export const useStyles = makeStyles()(() => ({
  inputContainer: {
    width: 140,
    position: 'relative',
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      minWidth: 40,
    },
  },
}));

export const CustomizedAutocompleteHours = (
  props: CustomizedAutocompleteHoursProps,
): JSX.Element => {
  const {
    threshold,
    direction,
    label,
    handleRangeChange,
    openingHourService,
    idx,
    timeIdx,
    value,
    disabled,
  } = props;

  const { classes } = useStyles();
  const ref = useRef<JSX.Element>();

  const { errors, getFieldProps } = useFormikContext<{
    openingHours: OpeningHour[];
  }>();

  const fieldProps = getFieldProps({
    name: `${direction}`,
  });

  const hrsOptions = useMemo(
    () => openingHourService.getHours(threshold, direction),
    [openingHourService, threshold, direction],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Tab' || e.key === 'Enter') {
        // @ts-ignore
        const currentValue = e.target.value.toString();

        if (currentValue.length > 0) {
          const searchInputValue = currentValue.replace(':', '');

          const filteredOptions = hrsOptions.filter(opt => {
            const checkedValue = opt.displayValue.replace(':', '');
            return checkedValue.startsWith(searchInputValue);
          });

          if (filteredOptions.length > 0) {
            handleRangeChange(filteredOptions[0].value, idx, timeIdx);
          }
        }
      }
    },
    [handleRangeChange, hrsOptions, idx, timeIdx],
  );

  return (
    <Autocomplete
      disabled={disabled}
      ref={ref}
      className={classes.inputContainer}
      value={hrsOptions.find(hr => hr.value === value)}
      onChange={(_, newValue) => {
        handleRangeChange(newValue?.displayValue as string, idx, timeIdx);
      }}
      filterOptions={(options, { inputValue }) => {
        const searchInputValue = inputValue.replace(':', '');

        if (inputValue.length > 0) {
          return options.filter(opt => {
            const checkedValue = opt.displayValue.replace(':', '');
            return checkedValue.startsWith(searchInputValue);
          });
        } else return options;
      }}
      popupIcon={<Arrow />}
      options={hrsOptions}
      getOptionLabel={option => option.displayValue}
      renderOption={(props, option) => (
        <Box component='li' {...props}>
          {option.displayValue}
        </Box>
      )}
      renderInput={params => {
        const errorValue = (errors.openingHours?.[idx] as OpeningHour | undefined)?.times?.[
          timeIdx
        ]?.[direction];

        const showError = typeof errorValue === 'string';

        return (
          <CustomizedTextField
            {...fieldProps}
            {...{ ...params }}
            variant='outlined'
            label={label}
            error={showError}
            helperText={showError ? errorValue : null}
          />
        );
      }}
      onKeyDown={handleKeyDown}
    />
  );
};
