import { IntlShape } from 'react-intl/src/types';
import * as Yup from 'yup';

export const SingleEditEventValidation = (intl: IntlShape) =>
  Yup.object().shape({
    date: Yup.string().nullable(),
    time: Yup.string().matches(
      /^(0\d|1\d|2[0-3]):[0-5]\d*$/,
      intl.formatMessage({
        id: 'form.event.error.appointments.time.required',
        defaultMessage: 'Time is required',
      }),
    ),
    duration: Yup.number()
      .nullable()
      .min(
        0,
        intl.formatMessage({
          id: 'form.event.field.appointments.duration.min',
          defaultMessage: 'No negative values',
        }),
      ),
    capacity: Yup.number()
      .nullable()
      .min(
        0,
        intl.formatMessage({
          id: 'form.event.error.appointments.duration.min',
          defaultMessage: 'No negative values',
        }),
      ),
  });
