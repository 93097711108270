import { Box } from '@mui/material';
import clsx from 'clsx';
import { Field, FieldArray, useFormikContext } from 'formik';

import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { ValidatePartnerDataType } from '@/Types';

import { useStartCodeStyles } from './AutoTabs.styles';
import { VerificationCodeType } from './VerificationCode';

export const StartCode = ({
  autoTab,
  handleChange,
  inputsRef,
  handlePaste,
}: VerificationCodeType) => {
  const { classes } = useStartCodeStyles();
  const { values } = useFormikContext<ValidatePartnerDataType>();

  return (
    <FieldArray
      name='startCode'
      render={() => (
        <Box className={classes.mainWrapper}>
          <Box className={classes.multiInputsWrapper}>
            {values.startCode.map((_, idx: number) => (
              <Box className={classes.inputBoxWrapper} key={idx}>
                <Field
                  onPaste={(e: ClipboardEvent) => (idx === 0 ? handlePaste(e, 4) : undefined)}
                  className={clsx(classes.textField, classes.startCodeField)}
                  key={`startCode.${idx}.partKey`}
                  name={`startCode.${idx}.partKey`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e.target.value, idx)
                  }
                  component={FormikTextField}
                  variant='outlined'
                  inputProps={{ maxLength: 4 }}
                  inputRef={(input: HTMLInputElement) =>
                    input && inputsRef.current && (inputsRef.current[idx] = input)
                  }
                  onKeyUp={(
                    e: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>,
                  ) => autoTab(e, idx)}
                  placeholder={(idx + 1).toString(10).repeat(4)}
                />

                {values.startCode.length - 1 !== idx ? (
                  <span className={classes.partKeyDash}>—</span>
                ) : (
                  ''
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    />
  );
};
