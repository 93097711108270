import { Box, TableCell, TableHead, TableRow } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Sticky from 'react-stickynode';

import { CaptionText, OverlineText, SubHeadlineDisplay } from '@/Components';
import { EquipmentAndServiceMap } from '@/Mappers';

import { useEquipmentTableHeadStyles } from './EquipmentAndService.styles';

export interface EquipmentAndServiceTableHeadProps {
  equipmentAndServiceContract?: boolean;
}

export const EquipmentAndServiceTableHead = (
  props: EquipmentAndServiceTableHeadProps,
): JSX.Element => {
  const { equipmentAndServiceContract } = props;
  const { classes } = useEquipmentTableHeadStyles();
  const [stickyBackgroundVisible, setStickyBackgroundVisible] = useState<boolean>(false);
  const [servicesHeader, setServicesHeader] = useState<string>('');
  const { formatMessage } = useIntl();

  const handleStickyStateChange = useCallback(
    (state: { status: number }) => {
      if (state.status === 2) {
        setStickyBackgroundVisible(true);
      } else if (state.status === 0) {
        setStickyBackgroundVisible(false);
      }
    },
    [setStickyBackgroundVisible],
  );

  useEffect(() => {
    if (!equipmentAndServiceContract) {
      const handleServicesHeader = () => {
        const servicesHeader = new EquipmentAndServiceMap().getServicesHeader();

        setServicesHeader(servicesHeader as string);
      };

      window.addEventListener('scroll', handleServicesHeader);

      return () => window.removeEventListener('scroll', handleServicesHeader);
    }
  }, [equipmentAndServiceContract]);

  const formHeader = (
    <>
      <OverlineText
        fontWeight={500}
        textContent={formatMessage({
          id: 'form.equipment_and_services.table.header.included',
          defaultMessage: 'INCLUDED',
        })}
      />
      <CaptionText
        textContent={formatMessage({
          id: 'form.equipment_and_services.table.header.included.hint',
          defaultMessage: 'Can be used free of charge by Hansefit members',
        })}
      />
    </>
  );

  const formHeaderExtra = (
    <>
      <OverlineText
        fontWeight={500}
        textContent={formatMessage({
          id: 'form.equipment_and_services.table.header.surcharge',
          defaultMessage: 'EXTRA CHARGE',
        })}
      />
      <CaptionText
        textContent={formatMessage({
          id: 'form.equipment_and_services.table.header.surcharge.hint',
          defaultMessage: 'Can only be used by Hansefit members at an additional cost',
        })}
      />
    </>
  );

  const formHeaderExcluded = (
    <>
      <OverlineText
        fontWeight={500}
        textContent={formatMessage({
          id: 'form.equipment_and_services.table.header.not_included',
          defaultMessage: 'not included',
        })}
      />
      <CaptionText
        textContent={formatMessage({
          id: 'form.equipment_and_services.table.header.not_included.hint',
          defaultMessage: 'not usable for Hansefit members',
        })}
      />
    </>
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell className={clsx(classes.tableCell)}>
          {!stickyBackgroundVisible && (
            <Box
              className={clsx(
                classes.categoryContainerSimple,
                classes.categoryContainerSimpleGroupHeader,
                classes.categoryContainerMockUp,
              )}>
              {servicesHeader && !equipmentAndServiceContract && (
                <SubHeadlineDisplay
                  textContent={formatMessage({
                    id: servicesHeader,
                    defaultMessage: servicesHeader,
                  })}
                />
              )}
            </Box>
          )}
          <Sticky top={130} innerClass={classes.stickyInner}>
            <Box
              className={clsx(
                classes.categoryContainerSimple,
                classes.categoryContainerSimpleGroupHeader,
              )}>
              {servicesHeader && !equipmentAndServiceContract && (
                <SubHeadlineDisplay
                  textContent={formatMessage({
                    id: servicesHeader,
                    defaultMessage: servicesHeader,
                  })}
                />
              )}
            </Box>
          </Sticky>
        </TableCell>
        <TableCell className={clsx(classes.tableCell, classes.tableCellBlue)}>
          {stickyBackgroundVisible && (
            <Box
              className={clsx(
                classes.categoryContainer,
                classes.categoryContainerFirst,
                classes.categoryContainerMockUp,
              )}>
              <Box className={classes.categoryContainerHeader}>{formHeader}</Box>
            </Box>
          )}
          <Sticky
            top={130}
            innerClass={classes.stickyInner}
            onStateChange={handleStickyStateChange}>
            <Box className={clsx(classes.categoryContainer, classes.categoryContainerFirst)}>
              <Box className={classes.categoryContainerHeader}>{formHeader}</Box>
            </Box>
          </Sticky>
        </TableCell>
        <TableCell className={clsx(classes.tableCell, classes.tableCellBlue)}>
          {stickyBackgroundVisible && (
            <Box className={clsx(classes.categoryContainer, classes.categoryContainerMockUp)}>
              <Box className={classes.categoryContainerHeader}>{formHeaderExtra}</Box>
            </Box>
          )}
          <Sticky top={130} innerClass={classes.stickyInner}>
            <Box
              className={clsx(classes.categoryContainer, {
                [classes.categoryContainerLast]: equipmentAndServiceContract,
              })}>
              <Box className={classes.categoryContainerHeader}>{formHeaderExtra}</Box>
            </Box>
          </Sticky>
        </TableCell>
        {!equipmentAndServiceContract && (
          <TableCell className={clsx(classes.tableCell, classes.tableCellBlue)}>
            {stickyBackgroundVisible && (
              <Box className={clsx(classes.categoryContainer, classes.categoryContainerMockUp)}>
                <Box className={classes.categoryContainerHeader}>{formHeaderExcluded}</Box>
              </Box>
            )}
            <Sticky top={130} innerClass={classes.stickyInner}>
              <Box className={clsx(classes.categoryContainer, classes.categoryContainerLast)}>
                <Box className={classes.categoryContainerHeader}>{formHeaderExcluded}</Box>
              </Box>
            </Sticky>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
