import { Box, Grid } from '@mui/material';
import { useIntl } from 'react-intl';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { Header, PageHeadline } from '@/Components';

import { MarketingMaterialsTable } from './Components/Table';

const MarketingMaterial = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Header showLanguageSwitcher={false} columnHeaderHeight={150} hiddenTabs onlyShowProfile>
        <Box mt='29px'>
          <PageHeadline
            textContent={formatMessage({
              id: 'marketing_material.title',
              defaultMessage: 'Marketing material',
            })}
          />
        </Box>
      </Header>

      <Box>
        <Box height={16} />
        <ContentContainer padding={0}>
          <Grid item xs={12}>
            <MarketingMaterialsTable />
          </Grid>
        </ContentContainer>
      </Box>
    </>
  );
};

export default MarketingMaterial;
