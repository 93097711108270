import { Box, Divider } from '@mui/material';
import { ConnectedFocusError } from 'focus-formik-error';
import { Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { DelayModalTypes, useAppContext } from '@/Context';
import { useConfirmDialog } from '@/Hooks/useConfirmDialog';
import { useUpdatePartnerDataMutation } from '@/Queries';
import { EquipmentsAndServicesPartial, Partner } from '@/Types';
import handleCatchError from '@/Utils/handleCatchError';

import { useEquipmentsAndServicesFormStyles } from './EquipmentAndService.styles';
import { EquipmentAndServiceContractTableBox } from './EquipmentAndServiceContractTableBox';
import { EquipmentAndServiceTableBox } from './EquipmentAndServiceTableBox';

export type EquipmentsAndServicesFormProps = {
  casPublicId: Partner['casPublicId'];
  partner: Partner;
  refCallback: (
    ref: React.RefObject<FormikProps<EquipmentsAndServicesPartial> | undefined>,
    tabNumber: number,
  ) => void;
  handlePrimaryCtaDisabled: (value: boolean) => void;
  setOpenToastMessage: (value: boolean) => void;
};

export const EquipmentsAndServicesForm = ({
  partner,
  refCallback,
  casPublicId,
  handlePrimaryCtaDisabled,
  setOpenToastMessage,
}: EquipmentsAndServicesFormProps) => {
  const {
    dispatch,
    state: { delayModal },
  } = useAppContext();

  const { classes } = useEquipmentsAndServicesFormStyles();

  const formikRef = useRef<FormikProps<EquipmentsAndServicesPartial>>(null);
  const handleConfirmDialog = useConfirmDialog();

  const initialState = useMemo<EquipmentsAndServicesPartial>(
    () => ({
      fitnessServices: partner.fitnessServices,
      wellnessServices: partner.wellnessServices,
      services: partner.services,
      contractServices: partner.contractServices,
    }),
    [partner],
  );

  const { mutateAsync: updatePartnerData } = useUpdatePartnerDataMutation();

  const handleChange = useCallback(
    (property: string, value: string, idx: number) => {
      formikRef.current?.setFieldValue(`${property}[${idx}].status`, value);
      handleConfirmDialog(true);
      handlePrimaryCtaDisabled(false);
    },
    [handleConfirmDialog, handlePrimaryCtaDisabled],
  );

  useEffect(() => {
    refCallback(formikRef, 0);
  }, [refCallback, formikRef]);

  const handleFormSubmit = async (values: EquipmentsAndServicesPartial) => {
    if (!delayModal.notShowProfileModal) {
      dispatch({ type: DelayModalTypes.SET_OPEN, payload: { open: true } });
    }

    const updatedData = {
      fitnessServices: Object.fromEntries(
        Object.values(values.fitnessServices).map(value => [value.key, value.status]),
      ),
      wellnessServices: Object.fromEntries(
        Object.values(values.wellnessServices).map(value => [value.key, value.status]),
      ),
      services: Object.fromEntries(
        Object.values(values.services).map(value => [value.key, value.status]),
      ),
    };

    try {
      await updatePartnerData({ casPublicId, updatedData });

      handlePrimaryCtaDisabled(true);
      handleConfirmDialog(false);
      setOpenToastMessage(true);
    } catch (error) {
      handleCatchError(error);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialState}
      validate={values => {
        const initialStateStringify = JSON.stringify(initialState);
        const currentStateStringify = JSON.stringify(values);

        if (initialStateStringify !== currentStateStringify) {
          handleConfirmDialog(true);
          handlePrimaryCtaDisabled(false);
        }
      }}
      onSubmit={handleFormSubmit}>
      {() => (
        <Box style={{ width: 1090 }}>
          <ConnectedFocusError />

          <ContentContainer>
            <Form>
              <EquipmentAndServiceTableBox partner={partner} handleChange={handleChange} />

              <Divider className={classes.divider} />

              <EquipmentAndServiceContractTableBox handleChange={handleChange} />
            </Form>
          </ContentContainer>
        </Box>
      )}
    </Formik>
  );
};
