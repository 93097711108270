import { Box, Fade, Grid } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import Sticky from 'react-stickynode';

import { Button, TabsNavigation } from '@/Components';
import { ConfirmationDialogTypes, useAppContext } from '@/Context';
import useCurrentUserAttr from '@/Hooks/useCurrentUserAttr';
import { handleIsStickyBackgroundColor } from '@/Mappers';
import { useGetPartnerQuery } from '@/Queries';
import { BreadcrumbProps } from '@/Types';

import { TranslateNotification } from '../../App/Infrastructure/I18n/Components/TranslateNotification';
import { ServerErrorNotification } from '../../App/Shared/ServerErrorNotification/Components/ServerErrorNotification';
import useIsFirstTranslation from '../../Hooks/useIsFirstTranslation';
import InlineContentLanguageSwitcher from '../LanguageSwitcher';
import { SiteAndProfileBar } from './SiteAndProfileBar';
import { useHeaderStyles } from './Styles/Header.styles';

type HeaderCtaProps = {
  variant?: 'text' | 'contained' | 'outlined';
  title: string;
  disabled: boolean;
  callback: () => void;
};

type HeaderProps = {
  currentTab?: number;
  handleTabChange?: (event: React.ChangeEvent<{}>, newValue: number) => void;
  tabs?: { label: string; disabled: boolean }[];
  breadcrumbs?: BreadcrumbProps[];
  callToActions?: {
    primary?: HeaderCtaProps;
    secondary?: HeaderCtaProps;
  };
  showLanguageSwitcher: boolean;
  hiddenTabs?: boolean;
  children?: JSX.Element;
  columnHeaderHeight?: number;
} & (
  | {
      onlyShowProfile?: true;
      header?: never;
    }
  | {
      onlyShowProfile?: false;
      header: string;
    }
);

export const Header = ({
  currentTab = 0,
  handleTabChange,
  header,
  breadcrumbs = [],
  tabs = [],
  callToActions: { primary, secondary } = {},

  showLanguageSwitcher,

  hiddenTabs,
  onlyShowProfile,
  children,
  columnHeaderHeight,
}: HeaderProps) => {
  const { classes } = useHeaderStyles(columnHeaderHeight)();
  const { dispatch } = useAppContext();
  const { data: partner } = useGetPartnerQuery();
  const { data: userAttr } = useCurrentUserAttr();
  const [stickyBackgroundVisible, setStickyBackgroundVisible] = useState<boolean>(false);

  const { isFirstTranslation, setIsFirstTranslation } = useIsFirstTranslation();

  const handleStickyStateChange = useCallback(
    (state: { status: number }) => {
      const isStickyBackground = handleIsStickyBackgroundColor(state.status);
      setStickyBackgroundVisible(isStickyBackground);
    },
    [setStickyBackgroundVisible],
  );

  const handleStateAndSubmit = useCallback(() => {
    dispatch({
      type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
      payload: { show: false },
    });
    primary?.callback();
  }, [dispatch, primary]);

  const handleAccept = useCallback(() => setIsFirstTranslation(false), [setIsFirstTranslation]);

  const tabChangeMiddleware = useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
      handleTabChange && handleTabChange(event, newValue);
    },
    [handleTabChange],
  );

  if (!partner) {
    return null;
  }

  return (
    <>
      {showLanguageSwitcher && isFirstTranslation && (
        <TranslateNotification handleClose={handleAccept} />
      )}
      {showLanguageSwitcher && (
        <Sticky top={-64}>
          <InlineContentLanguageSwitcher />
        </Sticky>
      )}
      <Fade in={stickyBackgroundVisible} timeout={{ enter: 250, exit: 50 }}>
        <Box className={classes.stickyBackground} />
      </Fade>
      <Sticky onStateChange={handleStickyStateChange} innerClass={classes.stickyInner}>
        <Grid
          container
          className={clsx(classes.headerContainer, {
            [classes.smallPaddingToBody]: stickyBackgroundVisible,
          })}>
          {partner && userAttr && (
            <Grid item xs={12}>
              <SiteAndProfileBar
                partner={partner}
                header={!onlyShowProfile && header ? header : ''}
                breadcrumbs={!onlyShowProfile ? breadcrumbs : []}
                userAttr={userAttr}
              />
            </Grid>
          )}

          {(!!primary?.title || !!secondary?.title || !!tabs.length) && !onlyShowProfile && (
            <Grid
              container
              className={clsx(classes.tabAndPublishContainer, {
                [classes.smallMarginToTopHeader]: stickyBackgroundVisible,
                [classes.smallMarginTop]: hiddenTabs,
              })}>
              <Grid item xs={5}>
                {tabs.length > 0 && (
                  <TabsNavigation
                    value={currentTab}
                    handleChange={tabChangeMiddleware}
                    tabs={tabs}
                  />
                )}
              </Grid>

              <Grid container item xs={7} className={classes.saveAndPublishContainer}>
                {secondary && !!secondary.title && secondary.callback && (
                  <Button
                    disabled={secondary.disabled || partner.readonly}
                    onClick={secondary.callback}
                    variant='outlined'
                    className={classes.saveAndPublishButton}>
                    {secondary.title}
                  </Button>
                )}
                {primary && !!primary.title && !!primary.callback && (
                  <Button
                    disabled={primary.disabled || partner.readonly}
                    onClick={handleStateAndSubmit}
                    className={classes.saveAndPublishButton}>
                    {primary.title}
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
          {children}
        </Grid>
      </Sticky>
      <ServerErrorNotification />
    </>
  );
};
