import { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

import { CustomDataGrid } from '@/App/Shared/DataGridTable/Table/DataGridTable';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { DateSelectionType, GetCompanyStatsResponseType } from '@/Types';

import StatisticsTableToolbar from './StatisticsTableToolbar';

const CheckInByCompanyTable = ({
  data = [],
  setDateRange,
  dateRange,
  isLoading,
}: {
  data?: GetCompanyStatsResponseType['data'];
  setDateRange: (date: DateSelectionType) => void;
  dateRange: DateSelectionType;
  isLoading: boolean;
}) => {
  const { formatMessage } = useMemoedIntl();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'company_name',
        flex: 1.5,
        display: 'flex',
        minWidth: 400,
        maxWidth: 500,
        headerName: formatMessage({
          id: 'view.statistic.check_in_all.table.header.company_name',
          defaultMessage: 'Company name',
        }),
      },
      {
        field: 'eus',
        flex: 0.6,
        display: 'flex',
        minWidth: 170,
        maxWidth: 210,
        headerName: formatMessage({
          id: 'view.statistic.check_in_all.table.header.eus',
          defaultMessage: 'Number of active users',
        }),
      },
      {
        field: 'checkins',
        flex: 0.5,
        display: 'flex',
        minWidth: 170,
        maxWidth: 210,
        headerName: formatMessage({
          id: 'view.statistic.check_in_all.table.header.number_ins',
          defaultMessage: 'Number of checkins',
        }),
      },
    ],
    [formatMessage],
  );

  return (
    <CustomDataGrid
      columns={columns}
      isRowSelectable={() => false}
      rows={data}
      columnHeaderHeight={48}
      getRowId={row => row.id}
      loading={isLoading}
      autoHeight
      pagination
      hideFooter={!data.length}
      // onSortModelChange={model => setSortedState(model)}
      slots={{
        toolbar: StatisticsTableToolbar,
      }}
      slotProps={{
        toolbar: {
          handleSubmit: setDateRange,
          value: dateRange,
          fieldsToExport: columns,
        },
      }}
    />
  );
};

export default CheckInByCompanyTable;
