import { Auth } from 'aws-amplify';
import { Dispatch } from 'react';

import { AnyStateAction } from '@/Context';
import { HttpService, MarketingMaterialService } from '@/Services';

export class MarketingMaterialsServiceFactory {
  public getInstance(dispatch: Dispatch<AnyStateAction>): MarketingMaterialService {
    return new MarketingMaterialService(new HttpService(Auth, dispatch));
  }
}
