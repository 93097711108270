import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useCookies } from 'react-cookie';

import {
  ConfirmationDialogAction,
  ConfirmationDialogReducer,
  ConfirmationDialogState,
} from './ConfirmationDialogReducer';
import {
  CourseManagerAction,
  CourseManagerReducer,
  CourseManagerState,
} from './CourseManagerReducer';
import {
  DelayModalActions,
  DelayModalReducer,
  DelayModalState,
  DelayModalTypes,
} from './DelayModalReducer';
import { ServerErrorAction, ServerErrorReducer, ServerErrorState } from './ServerErrorReducer';

export interface InitialState {
  confirmationDialog: ConfirmationDialogState;
  courseManager: CourseManagerState;
  serverError: ServerErrorState;
  delayModal: DelayModalState;
  cookieConsent: boolean | null;
}

export interface AppProviderProps {
  children: ReactNode;
}

const initialState: InitialState = {
  confirmationDialog: {
    show: false,
    message: '',
  },
  serverError: {
    show: false,
    message: '',
    height: 0,
  },
  courseManager: {
    events: null,
    updatedEvents: null,
    course: null,

    dirtyFilters: false,
  },
  delayModal: {
    open: false,
    notShowProfileModal: null,
    action: '',
  },
  cookieConsent: null,
};
export type AnyStateAction =
  | ConfirmationDialogAction
  | CourseManagerAction
  | ServerErrorAction
  | DelayModalActions
  | any;

export const MainReducer = (
  {
    confirmationDialog,
    courseManager,
    serverError,

    delayModal,
    cookieConsent,
  }: InitialState,
  action: AnyStateAction,
) => ({
  confirmationDialog: ConfirmationDialogReducer(
    confirmationDialog,
    action as ConfirmationDialogAction,
  ),
  courseManager: CourseManagerReducer(courseManager, action as CourseManagerAction),
  serverError: ServerErrorReducer(serverError, action as ServerErrorAction),
  delayModal: DelayModalReducer(delayModal, action as DelayModalActions),
  cookieConsent: action.type === 'cookieConsent' ? action.payload : cookieConsent,
});

export const AppContext = createContext<{
  state: InitialState;
  dispatch: Dispatch<AnyStateAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }: AppProviderProps) => {
  const [state, dispatch] = useReducer(MainReducer, initialState);
  const [cookies] = useCookies(['profileModal', 'cookieConsent']);
  const memoedContext = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useEffect(() => {
    if (state.delayModal.notShowProfileModal === null) {
      dispatch({
        type: DelayModalTypes.SET_NOT_SHOW_PROFILE,
        payload: { notShowProfileModal: cookies?.profileModal === 'true' },
      });
    }
  }, [cookies.profileModal, state.delayModal.notShowProfileModal, dispatch]);

  useEffect(() => {
    if (cookies.cookieConsent !== undefined && cookies.cookieConsent !== null) {
      dispatch({ type: 'cookieConsent', payload: cookies.cookieConsent === 'true' });
    }
  }, [cookies.cookieConsent, dispatch]);

  return <AppContext.Provider value={memoedContext}>{children}</AppContext.Provider>;
};
