import { Box } from '@mui/material';

import { DynamicBreadcrumbs, PageHeadline } from '@/Components';
import { BreadcrumbProps, Partner, UserAttr } from '@/Types';

import LanguageDropdown from './LanguageDropdown';
import { ProfileDropdown } from './ProfileDropdown';
import { useSiteAndProfileBarStyles } from './Styles/Header.styles';

type SiteAndProfileBarProps = {
  breadcrumbs: BreadcrumbProps[];
  header: string;
  userAttr: UserAttr;
  partner: Partner;
};

export const SiteAndProfileBar = ({
  breadcrumbs,
  header,
  userAttr,
  partner,
}: SiteAndProfileBarProps): JSX.Element => {
  const { classes } = useSiteAndProfileBarStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.leftContainer}>
        <DynamicBreadcrumbs items={breadcrumbs} />
        <Box className={classes.headerContainer}>
          <PageHeadline textContent={header} />
        </Box>
      </Box>
      <Box className={classes.rightContainer}>
        <ProfileDropdown userAttr={userAttr} partner={partner} />
        <LanguageDropdown />
      </Box>
    </Box>
  );
};
