import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@/Context';
import { PartnerCoursesServiceFactory } from '@/Services';
import { Partner } from '@/Types';

type useGetPartnerCoursesQueryProps = {
  casPublicId: Partner['casPublicId'];
  isActiveCourse: boolean;
  page: number;
  limit: number;
};

const useGetPartnerCoursesQuery = ({
  casPublicId,
  isActiveCourse,
  page,
  limit,
}: useGetPartnerCoursesQueryProps) => {
  const { dispatch } = useAppContext();
  const partnerCoursesService = new PartnerCoursesServiceFactory().getInstance(dispatch);

  const queryResult = useQuery(
    ['useGetPartnerCoursesQuery', isActiveCourse, casPublicId, page, limit],
    async () => await partnerCoursesService.getCourses(casPublicId, isActiveCourse, page, limit),
    {
      cacheTime: 0,
      enabled: !!casPublicId,
    },
  );

  return { ...queryResult, data: queryResult.data };
};

export default useGetPartnerCoursesQuery;
