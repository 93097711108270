import { Box, Grid } from '@mui/material';
import { Auth } from 'aws-amplify';
import { ConnectedFocusError } from 'focus-formik-error';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { Button } from '@/Components';
import { useConfirmDialog } from '@/Hooks/useConfirmDialog';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import { UsersMap } from '@/Mappers';
import { EventsService } from '@/Services';
import { CurrentUserFormik, Partner, Role, User, UserAttr } from '@/Types';
import { PartnerAccountValidation } from '@/Utils';

import { DeleteCurrentUserModal } from '../CurrentUser/DeleteCurrentUserModal';
import { useCurrentUserFormStyles } from './CurrentUserForm.styles';

type CurrentUserFormProps = {
  refCallback: (
    ref: React.RefObject<FormikProps<CurrentUserFormik> | undefined>,
    tabNumber: number,
  ) => void;
  userAttr: UserAttr;
  handleIsUserAttrDirty: (value: boolean) => void;
  userRole: User['role'];
  handleToastMessage: (value: boolean) => void;
  setPrimaryCtaDisabled: (value: boolean) => void;
  casPublicId: Partner['casPublicId'];
  usersService: UsersMap;
  eventsService: EventsService;
};

export const CurrentUserForm = ({
  refCallback,
  userAttr,
  handleIsUserAttrDirty,
  userRole,
  handleToastMessage,
  setPrimaryCtaDisabled,
  eventsService,
  casPublicId,
  usersService,
}: CurrentUserFormProps) => {
  const formikRef = useRef<FormikProps<CurrentUserFormik>>(null);
  const handleConfirmDialog = useConfirmDialog();
  const { classes } = useCurrentUserFormStyles();
  const [adminUsers, setAdminUsers] = useState<User[] | null>();
  const intl = useMemoedIntl();
  const { formatMessage } = intl;
  const navigate = useNavigate();

  const [initialState, setInitialState] = useState<CurrentUserFormik>({
    email: userAttr.email,
    given_name: userAttr.given_name,
    name: userAttr.name,
    isAdmin: userRole === Role.ADMIN,
    eventsTotalCount: null,
  });
  const currentLanguage = useCurrentLanguage();
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);

  useEffect(() => {
    refCallback(formikRef, 0);
  }, [refCallback, formikRef]);

  const savePartnerAccount = useCallback(
    async (values: CurrentUserFormik) => {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        email: values.email,
        given_name: values.given_name,
        name: values.name,
      });

      handleIsUserAttrDirty(true);
      handleToastMessage(true);
      handleConfirmDialog(false);
      setPrimaryCtaDisabled(true);
    },
    [handleIsUserAttrDirty, handleToastMessage, handleConfirmDialog, setPrimaryCtaDisabled],
  );

  const handleChangePassword = useCallback(() => {
    navigate(`/${currentLanguage}/change-password`);
  }, [currentLanguage, navigate]);

  const getCurrentUser = useCallback(async () => {
    const events = await eventsService.getEvents(casPublicId, 'ACTIVE', [
      { id: 'canceled', value: 'false' },
    ]);
    const usersResponse = await usersService.getUsers(casPublicId);
    const adminUsers = usersResponse?.data?.detail?.filter(
      user =>
        user.role === Role.ADMIN && user.cognitoId !== userAttr.sub && user.status === 'Active',
    );
    setAdminUsers(adminUsers || null);

    if (formikRef.current) {
      setInitialState(prev => ({
        ...prev,
        eventsTotalCount: events ? events.data.pagination?.totalCount : null,
      }));

      formikRef.current?.setFieldValue('eventsTotalCount', events.data.pagination?.totalCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAttr, casPublicId, formikRef]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  // Trigger validation on language change
  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [intl.locale]); // Run when the language changes

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={() => PartnerAccountValidation(intl)}
      initialValues={initialState}
      validateOnChange
      validate={values => {
        const initialStateStringify = JSON.stringify(initialState);
        const currentStateStringify = JSON.stringify(values);

        if (initialStateStringify !== currentStateStringify) {
          handleConfirmDialog(true);
          setPrimaryCtaDisabled(false);

          setInitialState(values);
        }
      }}
      onSubmit={savePartnerAccount}>
      {() => (
        <Box className={classes.contentContainerWrapper}>
          <ConnectedFocusError />
          <ContentContainer>
            <Form>
              <Grid container spacing={4}>
                <Grid item container spacing={4}>
                  <Grid item xs={12}>
                    <FormHeader
                      title={formatMessage({
                        id: 'form.current_user.header',
                        defaultMessage: 'My data',
                      })}
                    />
                  </Grid>
                </Grid>

                <Grid xs={8} item container>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Field
                        component={FormikTextField}
                        variant='outlined'
                        name='email'
                        type='text'
                        label={
                          <FormattedMessage
                            id='form.current_user.field.email.label'
                            defaultMessage='E-mail address'
                          />
                        }
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        component={FormikTextField}
                        variant='outlined'
                        name='given_name'
                        type='text'
                        label={
                          <FormattedMessage
                            id='form.current_user.field.given_name.label'
                            defaultMessage='First name*'
                          />
                        }
                        disabled={userRole !== Role.ADMIN}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        component={FormikTextField}
                        variant='outlined'
                        name='name'
                        type='text'
                        label={
                          <FormattedMessage
                            id='form.current_user.field.name.label'
                            defaultMessage='Last name*'
                          />
                        }
                        disabled={userRole !== Role.ADMIN}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid xs={8} item container>
                  <Grid container spacing={4}>
                    <Grid item xs={12} container direction='column'>
                      <Box display='flex' justifyContent='space-between'>
                        <Button variant='outlined' onClick={handleChangePassword}>
                          {formatMessage({
                            id: 'form.current_user.cta.change_password',
                            defaultMessage: 'Change password',
                          })}
                        </Button>

                        {userRole === Role.ADMIN && (
                          <Button variant='outlined' onClick={() => setDeleteModelOpen(true)}>
                            {formatMessage({
                              id: 'form.current_user.cta.delete_account',
                              defaultMessage: 'Delete user account',
                            })}
                          </Button>
                        )}
                      </Box>

                      {deleteModelOpen && (
                        <DeleteCurrentUserModal
                          adminUsers={adminUsers || []}
                          usersService={usersService}
                          userAttr={userAttr}
                          isOpen={deleteModelOpen}
                          handleClose={() => setDeleteModelOpen(false)}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </ContentContainer>
        </Box>
      )}
    </Formik>
  );
};
