import { TranslationType } from '@/Types';

export enum CourseTypes {
  ONLINE = 'OnlineCourse',
  ONSITE = 'OnsiteCourse',
}

export type Course = {
  courseId?: number;
  exclusiveCourse: boolean | undefined;
  courseName: TranslationType;
  internalName: string;
  courseType: CourseTypes;
  shortDescription: TranslationType;
  level: string;
  neededAccessoires: TranslationType;
  isNeededAccessoires?: boolean;
  courseInstructor: string;
  courseDuration: number | undefined;
  capacity: number;
  mainCategory: number | null;
  secondaryCategories: number[];
  published?: boolean;
  archived: boolean;
  startDate: string;
  endDate: string;
  nextEventDate: string;
  performedEvents: number;
  openEvents: number;
  totalEvents: number;
};

export type CoursesData = {
  pagination: {
    totalCount: number;
    itemsPerPage: number;
    pages: number;
    currentPage: number;
  };
  result: Course[];
};

export type EditAddressDataCourseType = {
  additionalInformation?: string;
  address: {
    street: string;
    streetNumber: string;
    city: string;
    postcode: string;
    coordLat: number;
    coordLong: number;
    country?: string;
    instructor?: string;
    instructorEmail?: string;
    instructorTelephone?: string;
  };
};
