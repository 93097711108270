import { Box, Grid } from '@mui/material';

import { Header } from '@/Components';

import FAQPage from './Components/FaqPage';

const Support = () => (
  <>
    <Header showLanguageSwitcher={false} columnHeaderHeight={80} hiddenTabs onlyShowProfile>
      <Box />
    </Header>
    <Box width={1090}>
      <Box height={16} />
      <Grid item xs={12}>
        <FAQPage />
      </Grid>
    </Box>
  </>
);

export default Support;
