import { Backdrop, Box, TextField } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';

import Theme from '@/App/Theme/Theme';
import {
  BodyTextRegular,
  BodyTextSmall,
  Button,
  CaptionText,
  OverlineText,
  PageHeadline,
  SubHeadlineDisplay,
} from '@/Components';
import { CourseManagerTypes, useAppContext } from '@/Context';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import { getCourseLevels, getCourseTypes } from '@/Mappers';
import {
  useCurrentPartnerIdQuery,
  useGetPartnerCourseCategoriesQuery,
  useGetPartnerCourseQuery,
} from '@/Queries';
import { formatDate } from '@/Utils';

import FigmaDesignTokens from '../../design/design-tokens.json';
import useStyles from './MobileAppPreview.style';
import { getMobileIcon } from './MobilePreviewIcons/MobilePreviewIcons';

type AppPreviewProps = {
  courseId: string;
  courseDate: string | Date | null | undefined;
  courseTime: string | Date | null | undefined;
  courseDuration: string | number | null;
  handleClose: () => void;
  streamLink?: string;
  streamPassword?: string;
  streamingHost?: string;
  streamInfo?: string;
  meetingId?: string;
  additionaInfo?: string;
  onsiteAddress?: {
    city: string | undefined;
    street: string | undefined;
    number: string | undefined;
    postalCode: string | undefined;
  };
};

const descriptionChars = 470;
const categoryInitialState = {
  iconUrlContour: '',
  iconUrlFilled: '',
  id: 0,
  imageUrl: '',
  longDescription: '',
  shortDescription: 'Example description',
};

const MobileAppPreview = ({
  courseId,
  courseDate,
  courseTime,
  courseDuration,
  handleClose,
  streamLink,
  streamPassword,
  streamingHost,
  streamInfo,
  meetingId,
  additionaInfo,
  onsiteAddress,
}: AppPreviewProps) => {
  const { classes } = useStyles()();

  const { formatMessage } = useIntl();
  const currentLanguage = useCurrentLanguage();

  const { data: casPublicId } = useCurrentPartnerIdQuery();

  const {
    state: {
      courseManager: { course },
    },
    dispatch,
  } = useAppContext();

  const { data: partnerCourseCategories } = useGetPartnerCourseCategoriesQuery({
    currentLanguage,
  });

  const { data: courseData, isSuccess } = useGetPartnerCourseQuery({
    casPublicId,
    courseId,
  });

  useEffect(() => {
    if (isSuccess && courseData) {
      dispatch({ type: CourseManagerTypes.SET_COURSE, payload: { course: courseData.data } });
    }
  }, [courseData, dispatch, isSuccess]);

  const courseDescription = course?.shortDescription[currentLanguage] || '';

  const [showMore, setShowMore] = useState(false);
  const hasLongDescription = courseDescription.length > descriptionChars;

  const courseLevels = getCourseLevels();
  const level = courseLevels.find(el => el.value === course?.level) || courseLevels[0];
  const courseTypes = getCourseTypes();
  const courseType = courseTypes.find(el => el.value === course?.courseType) || courseTypes[0];

  const allCategories = partnerCourseCategories?.data || [];
  const courseCategory = allCategories.length
    ? allCategories.find(el => parseInt(el.id) === course?.mainCategory)
    : categoryInitialState;

  const appointmentDate = courseDate ? new Date(courseDate) : new Date();
  const dateToText = appointmentDate.toLocaleDateString(currentLanguage, { weekday: 'short' });
  const formattedDate = `${dateToText} ${formatDate(appointmentDate)}, ${courseTime}`;

  return createPortal(
    <Backdrop open className={classes.backdrop}>
      <Box className={classes.contentContainer}>
        <Box className={classes.headContainer}>
          <Box>
            <PageHeadline
              textContent={formatMessage({
                id: 'course_manger.preview.mobile_app.page_headline',
                defaultMessage: 'Course Preview',
              })}
            />
            <BodyTextSmall
              textContent={formatMessage({
                id: 'course_manger.preview.mobile_app.subheadline',
                defaultMessage:
                  'This is simply a prototype, and the appearance may differ based on the device being utilized.',
              })}
            />
          </Box>
          <Box className={classes.btnGroup}>
            <Button onClick={() => handleClose()}>
              {formatMessage({
                id: 'course_manger.preview.mobile_app.cta.close',
                defaultMessage: 'Close preview',
              })}
            </Button>
          </Box>
        </Box>
        <Box className={classes.phoneLayout}>
          {course && (
            <Box className={classes.mockupContainer}>
              <Box className={classes.imageContainer}>
                {courseCategory?.imageUrl && <img alt='' src={courseCategory.imageUrl} />}
              </Box>
              <Box sx={{ padding: '20px 16px' }}>
                <Box className={classes.categoryContainer}>
                  {courseCategory?.iconUrlContour && (
                    <Box className={classes.imgContainer}>
                      <img
                        alt=''
                        className={classes.categoryItem}
                        src={courseCategory.iconUrlContour}
                      />
                    </Box>
                  )}
                  <CaptionText textContent={courseCategory?.shortDescription || ''} />
                </Box>
                <SubHeadlineDisplay
                  textContent={course.courseName[currentLanguage]}
                  className={classes.mockupPageHeadline}
                />
                <BodyTextSmall
                  textContent={formatMessage({
                    id: courseType.id,
                    defaultMessage: courseType.defaultMessage,
                  })}
                  textDecoration='underline'
                  className={classes.vspacing}
                />

                <TextField
                  value={formattedDate}
                  className={clsx(classes.vspacing, classes.textField)}
                />

                <>
                  <Box className={classes.highlightsItem}>
                    {getMobileIcon('courseDuration')}
                    <OverlineText
                      textContent={formatMessage(
                        {
                          id: 'course_manger.preview.mobile_app.mockup.course_duration',
                          defaultMessage: '{courseDuration} min',
                        },
                        { courseDuration },
                      )}
                      textTransform='capitalize'
                    />
                  </Box>

                  <Box className={classes.highlightsItem}>
                    {getMobileIcon('courseLevel')}
                    <OverlineText
                      textContent={formatMessage({
                        id: level.id,
                        defaultMessage: level.defaultMessage,
                      })}
                      textTransform='capitalize'
                    />
                  </Box>
                  <Box className={clsx(classes.highlightsItem, classes.vspacing)}>
                    {getMobileIcon('courseEquipment')}
                    <OverlineText
                      textContent={
                        course.neededAccessoires[currentLanguage] ||
                        formatMessage({
                          id: 'course_manger.preview.mobile_app.mockup.course.no_equipment',
                          defaultMessage: 'No equipment needed',
                        })
                      }
                      textTransform='capitalize'
                    />
                  </Box>
                </>
                <>
                  <BodyTextRegular
                    textContent={formatMessage({
                      id: 'course_manger.preview.mobile_app.mockup.subheadline.course_description',
                      defaultMessage: 'Course description',
                    })}
                    fontWeight={600}
                    textTransform='uppercase'
                  />
                  <BodyTextSmall
                    textContent={
                      showMore
                        ? courseDescription
                        : courseDescription.substring(0, descriptionChars) +
                          (hasLongDescription ? '...' : '')
                    }
                    className={classes.textDescription}
                  />
                  {hasLongDescription ? (
                    <div
                      onClick={() => {
                        setShowMore(!showMore);
                      }}>
                      <BodyTextSmall
                        textContent={
                          !showMore
                            ? formatMessage({
                                id: 'course_manger.preview.mobile_app.mockup.subheadline.course_description.button_more',
                                defaultMessage: 'Show more',
                              })
                            : formatMessage({
                                id: 'course_manger.preview.mobile_app.mockup.subheadline.course_description.button_less',
                                defaultMessage: 'Show less',
                              })
                        }
                        customColor={Theme.palette.primary.main}
                        fontWeight={900}
                        textDecoration='underline'
                        className={clsx(classes.vspacing, classes.textBtn)}
                      />
                    </div>
                  ) : null}
                </>
                {streamLink && (
                  <>
                    <BodyTextRegular
                      textContent={formatMessage({
                        id: 'course_manger.preview.mobile_app.mockup.subheadline.streaming_info',
                        defaultMessage: 'Streaming information',
                      })}
                      fontWeight={600}
                      textTransform='uppercase'
                    />
                    <BodyTextSmall
                      textContent={formatMessage(
                        {
                          id: 'course_manger.preview.mobile_app.mockup.streaming_provider',
                          defaultMessage: 'Streaming provider: {streamingHost}',
                        },
                        { streamingHost },
                      )}
                      className={classes.textDescription}
                    />

                    <BodyTextSmall
                      textContent={`${formatMessage({
                        id: 'form.event.field.stream_settings.stream_link.app_preview',
                        defaultMessage: 'Access link to the live stream',
                      })}: ${streamLink}`}
                      className={classes.textDescription}
                    />
                    {streamPassword && (
                      <BodyTextSmall
                        textContent={`${formatMessage({
                          id: 'form.event.field.stream_settings.stream_password.label',
                          defaultMessage: 'Passwort',
                        })}: ${streamPassword}`}
                        className={classes.textDescription}
                      />
                    )}
                    {meetingId && (
                      <BodyTextSmall
                        textContent={`${formatMessage({
                          id: 'form.event.field.stream_settings.meeting_id.label',
                          defaultMessage: 'Meeting ID',
                        })}: ${meetingId}`}
                        className={classes.textDescription}
                      />
                    )}
                  </>
                )}
                {streamInfo && (
                  <BodyTextSmall textContent={streamInfo} className={classes.vspacing} />
                )}
                {onsiteAddress?.city && (
                  <>
                    <BodyTextRegular
                      textContent={formatMessage({
                        id: 'course_manger.preview.mobile_app.mockup.subheadline.address_info',
                        defaultMessage: 'Contact',
                      })}
                      fontWeight={600}
                      textTransform='uppercase'
                    />

                    <BodyTextSmall
                      textContent={`${onsiteAddress?.street} ${onsiteAddress?.number}, ${onsiteAddress?.postalCode} ${onsiteAddress?.city}`}
                      className={classes.textDescription}
                    />
                  </>
                )}
                {additionaInfo && (
                  <>
                    <BodyTextRegular
                      textContent={formatMessage({
                        id: 'course_manger.preview.mobile_app.mockup.subheadline.additional_info',
                        defaultMessage: 'Information',
                      })}
                      fontWeight={600}
                      textTransform='uppercase'
                    />

                    <BodyTextSmall
                      textContent={additionaInfo}
                      className={classes.textDescription}
                    />
                  </>
                )}
                <Button className={classes.cta} textTransform>
                  {formatMessage({
                    id: 'course_manger.preview.mobile_app.mockup.button.cta',
                    defaultMessage: 'Book appointment',
                  })}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <BodyTextRegular
          textContent={formatMessage({
            id: 'course_manger.preview.mobile_app.text.bottom',
            defaultMessage: 'Preview',
          })}
          customColor={FigmaDesignTokens.White}
          className={classes.footer}
        />
      </Box>
    </Backdrop>,
    document.body,
  );
};

export default MobileAppPreview;
