import { motion } from 'framer-motion';
import { cloneElement, isValidElement, useMemo } from 'react';
import { Toast } from 'react-hot-toast';

import useStyles from './ToastItem.styles';

const ToastItem = ({
  message,
  duration,
  createdAt,
  id,
  style,
  index,
}: Toast & { index: number }) => {
  const { classes } = useStyles(style)();

  const renderedMessage = useMemo(() => {
    if (!message) {
      return null;
    }

    if (isValidElement(message)) {
      return cloneElement(message, {
        id,
        duration,
        createdAt,
      } as React.Attributes);
    }

    return <div>{message}</div>;
  }, [message, id, duration, createdAt]);

  return (
    <motion.div
      layout
      initial={{ y: 0, opacity: 0 }}
      animate={{ y: 40 * index, opacity: 1 }}
      transition={{ duration: 0.2 }}
      className={classes.container}
      key={id}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}>
        {renderedMessage}
      </div>
    </motion.div>
  );
};

export default ToastItem;
