import { Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ConnectedFocusError } from 'focus-formik-error';
import { Form, Formik, FormikProps } from 'formik';
import qs from 'qs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { CourseManagerTypes, useAppContext } from '@/Context';
import { useConfirmDialog } from '@/Hooks/useConfirmDialog';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import { getAddEventPathname, getCoursesPathname, isNewCourseView } from '@/Mappers';
import { initialCourseData } from '@/Models';
import {
  COURSE_ACTIONS,
  DeeplServiceFactory,
  PartnerCoursesServiceFactory,
  PartnerServiceFactory,
} from '@/Services';
import { Category, Course, CourseTypes, Partner } from '@/Types';
import { CourseValidation } from '@/Utils';
import { CrazyUseRefCallbackStuffTypes, isCourseFormRef } from '@/Utils/formRefTypeguards';
import handleCatchError from '@/Utils/handleCatchError';

import { CourseCategories } from './CourseCategories';
import CourseData from './CourseData';

type NewCourseFormProps = {
  casPublicId: Partner['casPublicId'];
  categories: Category[];
  refCallback: (ref: CrazyUseRefCallbackStuffTypes, tabNumber: number) => void;
  handleToastMessage: (value: boolean) => void;
  handlePrimaryCtaDisabled: (value: boolean) => void;
};

export const CourseForm = ({
  categories,
  casPublicId,
  refCallback,
  handleToastMessage,
  handlePrimaryCtaDisabled,
}: NewCourseFormProps) => {
  const formikRef = useRef<FormikProps<Partial<Course>>>(null);
  const handleConfirmDialog = useConfirmDialog();
  const currentLanguage = useCurrentLanguage();
  const location = useLocation();
  const navigate = useNavigate();

  const intl = useIntl();
  const [template, setTemplate] = useState<Course>();
  const { state, dispatch } = useAppContext();
  const { courseManager } = state;
  const { course } = courseManager;
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const queryClient = useQueryClient();

  const [hasTemplateQueryParam] = useState(!!queryParams.template);
  const [isFetchingTemplate, setIsFetchingTemplate] = useState(false);

  const partnerCoursesService = useMemo(
    () => new PartnerCoursesServiceFactory().getInstance(dispatch),
    [dispatch],
  );
  const partnerService = useMemo(
    () => new PartnerServiceFactory().getInstance(dispatch),
    [dispatch],
  );
  const deeplService = useMemo(() => new DeeplServiceFactory().getInstance(dispatch), [dispatch]);

  const saveCourse = useCallback(
    async (values: Partial<Course>) => {
      try {
        const data = {
          ...values,
          capacity: values.capacity as number,
          mainCategory: values.mainCategory as number,
          internalName: values.internalName?.trim(),
          courseName: {
            de: values.courseName?.de.trim() || '',
            en: values.courseName?.en.trim() || '',
          },
          shortDescription: {
            de: values.shortDescription?.de.trim() || '',
            en: values.shortDescription?.en.trim() || '',
          },
        };

        delete data.isNeededAccessoires;
        let response;
        if (values.courseId) {
          if (formikRef.current?.status.action === COURSE_ACTIONS.SAVE) {
            delete data.archived;

            if (values.courseType === CourseTypes.ONLINE) {
              response = await partnerCoursesService.updateOnlineCourse(
                casPublicId,
                values.courseId,
                data,
              );
            } else {
              response = await partnerCoursesService.updateOnsiteCourse(
                casPublicId,
                values.courseId,
                data,
              );
            }
          }

          if (formikRef.current?.status.action === COURSE_ACTIONS.ARCHIVE) {
            response = await partnerCoursesService.archiveCourse(casPublicId, values.courseId);
          }

          if (formikRef.current?.status.action === COURSE_ACTIONS.DELETE) {
            response = await partnerCoursesService.deleteCourse(casPublicId, values.courseId);
          }

          if (response && !response.isAxiosError) {
            handleConfirmDialog(false);
            handlePrimaryCtaDisabled(true);

            let pathname: string;
            if (
              (values.openEvents && values.openEvents > 0) ||
              formikRef.current?.status.action === COURSE_ACTIONS.DELETE ||
              formikRef.current?.status.action === COURSE_ACTIONS.ARCHIVE
            ) {
              pathname = getCoursesPathname(currentLanguage, '?success=show');
            } else {
              pathname = getAddEventPathname(currentLanguage, values.courseId);
            }

            dispatch({ type: CourseManagerTypes.SET_COURSE, payload: { course: values } });
            handleToastMessage(true);
            navigate(pathname);
          }
        } else {
          let response;

          if (values.courseType === CourseTypes.ONLINE) {
            response = await partnerCoursesService.createOnlineCourse(casPublicId, data);
          } else {
            response = await partnerCoursesService.createOnsiteCourse(casPublicId, data);
          }

          if (!response.isAxiosError) {
            handleConfirmDialog(false);
            handlePrimaryCtaDisabled(true);
            dispatch({ type: CourseManagerTypes.SET_COURSE, payload: { course: values } });

            const pathname = getAddEventPathname(currentLanguage, response.data.courseId);

            handleToastMessage(true);
            navigate(pathname);
          } else return;
        }

        queryClient.invalidateQueries({ queryKey: ['getPartnerCourses'] });
      } catch (error) {
        handleCatchError(error);
      }
    },
    [
      dispatch,
      partnerCoursesService,
      casPublicId,
      handleConfirmDialog,
      handlePrimaryCtaDisabled,
      handleToastMessage,
      navigate,
      currentLanguage,
      queryClient,
    ],
  );

  useEffect(() => {
    if (isCourseFormRef(formikRef) && formikRef) {
      refCallback(formikRef, 0);
    }
  }, [refCallback, formikRef]);

  useEffect(() => {
    if (isNewCourseView(location.pathname) && courseManager.course) {
      dispatch({
        type: CourseManagerTypes.SET_COURSE,
        payload: { course: null },
      });
      formikRef.current?.resetForm({ values: undefined });
    }
  }, [dispatch, courseManager.course, formikRef, location]);

  const handleForm = useCallback(async () => {
    if (hasTemplateQueryParam && !template && !isFetchingTemplate) {
      setIsFetchingTemplate(true);
      const partnerId = await partnerService.getCurrentPartnerId();
      const response = await partnerCoursesService.getCourse(
        partnerId,
        parseInt(queryParams.template as string),
      );

      const mutatedCourseData = {
        ...response.data,
        courseId: undefined,
        internalName: 'kopie_von_' + response.data.internalName,
      };

      setTemplate(mutatedCourseData);
      setIsFetchingTemplate(false);
      handleConfirmDialog(true);
      handlePrimaryCtaDisabled(false);
      if (formikRef.current) {
        formikRef.current?.resetForm({ values: mutatedCourseData });
      }
    }
  }, [
    partnerService,
    handlePrimaryCtaDisabled,
    handleConfirmDialog,
    queryParams,
    partnerCoursesService,
    hasTemplateQueryParam,
    template,
    isFetchingTemplate,
    formikRef,
  ]);

  useEffect(() => {
    handleForm();
  }, [handleForm]);

  // Trigger validation on language change
  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [intl.locale]); // Run when the language changes

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={() => CourseValidation(currentLanguage, intl, deeplService)}
      initialValues={course || initialCourseData}
      validateOnBlur
      validateOnChange={false}
      validateOnMount
      onSubmit={saveCourse}>
      {() => (
        <Form>
          <ConnectedFocusError />
          <Grid container spacing={2}>
            {((hasTemplateQueryParam && template) || !hasTemplateQueryParam) && (
              <>
                <CourseData
                  deeplService={deeplService}
                  handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
                />
                <CourseCategories
                  categories={categories}
                  handlePrimaryCtaDisabled={handlePrimaryCtaDisabled}
                />
              </>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
