import { FormControlLabel, Grid, RadioGroup } from '@mui/material';
import { ManagedUpload } from 'aws-sdk/lib/s3/managed_upload';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { CustomizedRadio } from '@/App/Shared/Form/Components/Customized/CustomizedRadio';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { SubHeader } from '@/App/Shared/Form/Components/Header/SubHeader';
import { Dropzone, InfoMessage, UploadIndicator } from '@/Components';
import { ConfirmationDialogTypes, DelayModalTypes, useAppContext } from '@/Context';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import useIsFirstTranslation from '@/Hooks/useIsFirstTranslation';
import useMemoedIntl from '@/Hooks/useMemoedIntl';
import useReadStorage from '@/Hooks/useReadStorage';
import { useUploadCancel } from '@/Hooks/useUploadCancel';
import { getDefaultFilename } from '@/Mappers';
import { useTranslationMutation } from '@/Queries';
import { AssetServiceFactory } from '@/Services';
import { LanguageType, Partner, Upload, VideoAsset } from '@/Types';
import { getTargetLanguage, removeSymbols } from '@/Utils';
import { isVideoFormRef } from '@/Utils/formRefTypeguards';

import { AssetPreview } from '../Assets/AssetPreview';
import { VideoLiveAssetPreview } from '../Assets/VideoLiveAssetPreview';
import { useVideoStyles } from './Video.styles';

type VideoFormProps = {
  casPublicId: Partner['casPublicId'];
  partner?: Partner | null;
  videoAsset: VideoAsset;
  refCallback: (ref: React.RefObject<FormikProps<VideoAsset>>, tabNumber: number) => void;
  handlePrimaryCtaDisabled: (value: boolean) => void;
  setOpenToastMessage: (value: boolean) => void;
  refetchAssets: () => void;
};

export const VideoForm = ({
  partner,
  refCallback,
  videoAsset,
  casPublicId,
  handlePrimaryCtaDisabled,
  setOpenToastMessage,
  refetchAssets,
}: VideoFormProps) => {
  const { classes } = useVideoStyles();
  const {
    dispatch,
    state: { delayModal },
  } = useAppContext();

  const pageLanguage = useCurrentLanguage();
  const translationLanguage =
    useReadStorage<LanguageType>('vpp_translation_content_language', 'LOCAL_STORAGE') ||
    pageLanguage;
  const { isFirstTranslation, setIsFirstTranslation } = useIsFirstTranslation();

  const formikRef = useRef<FormikProps<VideoAsset>>(null);

  const { formatMessage } = useMemoedIntl();
  const [initialState, setInitialState] = useState<VideoAsset>(videoAsset);
  const [deleteAssetIdQueue, setDeleteAssetIdQueue] = useState<number[]>([]);
  const [youtubeTypeBackup, setYoutubeTypeBackup] = useState<VideoAsset | undefined>();
  const [vimeoTypeBackup, setVimeoTypeBackup] = useState<VideoAsset | undefined>();
  const [assetTypeBackup, setAssetTypeBackup] = useState<VideoAsset | undefined>();

  const { uploads, handleCancel, setUploads } = useUploadCancel();

  const assetService = useMemo(() => new AssetServiceFactory().getInstance(dispatch), [dispatch]);

  const handleProviderChange = useCallback(
    (e: React.MouseEvent<HTMLLabelElement>) => {
      if (!!partner?.readonly) return;
      if (!formikRef.current) return;
      if (!e) return;
      const elTarget = e.target as HTMLInputElement;

      const currentType = formikRef.current.values.videoSettings.type;
      const values = formikRef.current.values;

      if (
        (elTarget.value === 'youtube' && youtubeTypeBackup) ||
        (elTarget.value === 'vimeo' && vimeoTypeBackup) ||
        (elTarget.value === 'asset' && assetTypeBackup)
      ) {
        if (elTarget.value === 'youtube') {
          if (currentType === 'asset') {
            setAssetTypeBackup(values);
          }

          if (currentType === 'vimeo') {
            setVimeoTypeBackup(values);
          }

          formikRef.current.setValues({ ...youtubeTypeBackup } as VideoAsset);
        }

        if (elTarget.value === 'vimeo') {
          if (currentType === 'youtube') {
            setYoutubeTypeBackup(values);
          }

          if (currentType === 'asset') {
            setAssetTypeBackup(values);
          }

          formikRef.current.setValues({ ...vimeoTypeBackup } as VideoAsset);
        }

        if (elTarget.value === 'asset') {
          if (currentType === 'youtube') {
            setYoutubeTypeBackup(values);
          }

          if (currentType === 'vimeo') {
            setVimeoTypeBackup(values);
          }

          formikRef.current.setValues({ ...assetTypeBackup } as VideoAsset);
        }
      } else {
        if (currentType === 'youtube') {
          setYoutubeTypeBackup(values);
        }

        if (currentType === 'vimeo') {
          setVimeoTypeBackup(values);
        }

        if (currentType === 'asset') {
          setAssetTypeBackup(values);
        }

        formikRef.current.setFieldValue('videoSettings.type', elTarget.value);
        formikRef.current.setFieldValue('videoSettings.uri', '');
        formikRef.current.setFieldValue('videoSettings.assetId', 0);
        formikRef.current.setFieldTouched('videoSettings.type', true);
        formikRef.current.setFieldTouched('videoSettings.uri', true);
        formikRef.current.setFieldTouched('videoSettings.assetId', true);
      }
    },
    [
      partner,
      formikRef,
      youtubeTypeBackup,
      vimeoTypeBackup,
      assetTypeBackup,
      setYoutubeTypeBackup,
      setVimeoTypeBackup,
      setAssetTypeBackup,
    ],
  );

  const handleDrop = useCallback(
    async (files: File[]) => {
      if (files.length > 0) {
        const file: File = files[0];

        const filename = getDefaultFilename(file.name);
        const managedUpload = await assetService.S3Upload(
          casPublicId,
          filename,
          'Video',
          file,
          (progressEvent: ManagedUpload.Progress) => {
            const upload: Upload = {
              file: files[0],
              progress: progressEvent,
              managedUpload,
            };

            setUploads([upload]);
            handlePrimaryCtaDisabled(false);
          },
          err => {
            if (err) {
              setUploads([
                {
                  file,
                  errorMessage: formatMessage({
                    id: 'view.photo_and_video.video_upload.upload.error.failed',
                    defaultMessage: 'Upload failed',
                  }),
                },
              ]);
              return;
            }

            assetService
              .assignVideo(casPublicId, {
                filename,
                originalFilename: file.name,
              })
              .then(response => {
                if (!response.isAxiosError) {
                  if (formikRef.current) {
                    formikRef.current.setFieldValue('videoSettings.assetId', response.data.assetId);
                    formikRef.current.setFieldValue('videoSettings.type', 'asset');
                    formikRef.current.setFieldValue('videoSettings.uri', filename);
                    formikRef.current.setFieldValue('videoSettings.originalFilename', file.name);
                  }

                  setUploads([]);
                  setOpenToastMessage(true);
                }
              });
          },
        );
      }
    },
    [
      assetService,
      casPublicId,
      formatMessage,
      handlePrimaryCtaDisabled,
      setOpenToastMessage,
      setUploads,
    ],
  );

  const { mutateAsync: translationMutate } = useTranslationMutation();

  const handleTranslation = useCallback(
    async (textToTranslate: string, field: string) => {
      if (formikRef.current?.setFieldValue && translationLanguage) {
        await translationMutate({
          currentValue: textToTranslate,
          field,
          currentLanguage: translationLanguage,
          setFieldValue: formikRef.current?.setFieldValue,
          isFirstTranslation: isFirstTranslation as boolean,
          setIsFirstTranslation,
          targetLanguage: getTargetLanguage(translationLanguage),
        });
      }
    },
    [translationLanguage, translationMutate, isFirstTranslation, setIsFirstTranslation],
  );

  const handleDeleteAsset = useCallback(
    (assetId: number) => {
      deleteAssetIdQueue.push(assetId);
      formikRef.current?.setFieldValue('videoSettings.assetId', 0);
      formikRef.current?.setFieldValue('videoSettings.uri', '');
      handlePrimaryCtaDisabled(false);
    },
    [formikRef, deleteAssetIdQueue, handlePrimaryCtaDisabled],
  );

  useEffect(() => {
    if (isVideoFormRef(formikRef)) {
      refCallback(formikRef, 2);
    }
  }, [refCallback, formikRef]);

  useEffect(() => {
    formikRef.current?.setValues(videoAsset);
    setInitialState(videoAsset);
    dispatch({
      type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
      payload: { show: false },
    });
    handlePrimaryCtaDisabled(true);
  }, [videoAsset, handlePrimaryCtaDisabled, dispatch]);

  const handleSubmit = async (values: VideoAsset) => {
    let videoUrl = values.videoSettings.uri;

    if (videoUrl && values.videoSettings.type === 'youtube') {
      const splitdUrl = videoUrl.match(
        /(youtu.*be.*)\/(watch\?v=|embed\/|shorts|)(.*?((?=[&#?])|$))/,
      );

      videoUrl = splitdUrl ? `https://www.youtube.com/watch?v=${splitdUrl[3]}` : '';
      if (!videoUrl) formikRef.current?.setFieldValue('videoSettings.uri', '');
    }

    if (!delayModal.notShowProfileModal) {
      dispatch({ type: DelayModalTypes.SET_OPEN, payload: { open: true } });
    }

    await assetService.deleteAllAssets(casPublicId, deleteAssetIdQueue);

    const videoAsset = {
      videoSettings: {
        type: values.videoSettings.type,
        assetId: values.videoSettings.assetId === 0 ? null : values.videoSettings.assetId,
        uri: videoUrl,
        originalFilename: values.videoSettings.originalFilename,
      },
      title: values.title,
    };

    const response = await assetService.updateVideoAsset(casPublicId, videoAsset);

    if (!response.isAxiosError) {
      setUploads([]);
      setDeleteAssetIdQueue([]);
      setYoutubeTypeBackup(undefined);
      setVimeoTypeBackup(undefined);
      setAssetTypeBackup(undefined);
      handlePrimaryCtaDisabled(true);
      setOpenToastMessage(true);
      refetchAssets();
    } else {
      dispatch({
        type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
        payload: { show: true },
      });
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      validateOnBlur={false}
      validateOnChange
      validate={values => {
        const initialStateStringify = JSON.stringify(initialState);
        const currentStateStringify = JSON.stringify(values);

        if (initialStateStringify !== currentStateStringify) {
          dispatch({
            type: ConfirmationDialogTypes.SET_CONFIRMATION_DIALOG,
            payload: { show: true },
          });
          setInitialState(values);
          handlePrimaryCtaDisabled(false);
        }
      }}
      initialValues={initialState}
      onSubmit={handleSubmit}>
      {({ values }) => (
        <ContentContainer>
          <Form>
            <Grid container spacing={2}>
              <Grid item container spacing={4}>
                <Grid item xs={12}>
                  <FormHeader
                    title={formatMessage({
                      id: 'view.photo_and_video.video_upload',
                      defaultMessage: 'Upload failed',
                    })}
                  />
                </Grid>
              </Grid>

              <Grid item xs={7}>
                <Grid container spacing={4}>
                  <Grid item xs={12} container spacing={0}>
                    <Grid xs={12} item>
                      <SubHeader
                        title={formatMessage({
                          id: 'form.photo_and_video.video_upload.choose_provider.header',
                          defaultMessage: 'Choose an option',
                        })}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <Field
                        name='videoSettings.type'
                        component={RadioGroup}
                        className={classes.uploadRadioGroupContainer}>
                        <FormControlLabel
                          disabled={!!partner?.readonly}
                          value='youtube'
                          control={<CustomizedRadio color='primary' />}
                          label={formatMessage({
                            id: 'form.photo_and_video.video_upload.choose_provider.youtube',
                            defaultMessage: 'Youtube',
                          })}
                          className={classes.uploadRadioGroupLabel}
                          checked={values.videoSettings.type === 'youtube'}
                          onClick={handleProviderChange}
                        />
                        <FormControlLabel
                          disabled={!!partner?.readonly}
                          value='vimeo'
                          control={<CustomizedRadio color='primary' />}
                          label={formatMessage({
                            id: 'form.photo_and_video.video_upload.choose_provider.vimeo',
                            defaultMessage: 'Vimeo',
                          })}
                          className={classes.uploadRadioGroupLabel}
                          checked={values.videoSettings.type === 'vimeo'}
                          onClick={handleProviderChange}
                        />
                        <FormControlLabel
                          disabled={!!partner?.readonly}
                          value='asset'
                          control={<CustomizedRadio color='primary' />}
                          label={formatMessage({
                            id: 'form.photo_and_video.video_upload.choose_provider.upload',
                            defaultMessage: 'Upload from computer',
                          })}
                          className={classes.uploadRadioGroupLabel}
                          checked={values.videoSettings.type === 'asset'}
                          onClick={handleProviderChange}
                        />
                      </Field>
                    </Grid>
                  </Grid>
                  {(values.videoSettings.type === 'youtube' ||
                    values.videoSettings.type === 'vimeo') && (
                    <>
                      <Grid item xs={12}>
                        <Field
                          disabled={!!partner?.readonly}
                          component={FormikTextField}
                          variant='outlined'
                          name='videoSettings.uri'
                          type='text'
                          label={formatMessage({
                            id: 'form.photo_and_video.video_upload.video_settings_uri.label',
                            defaultMessage: 'Link / URL Video',
                          })}
                        />
                      </Grid>

                      {values.videoSettings.uri && values.videoSettings.type && (
                        <VideoLiveAssetPreview
                          videoUrl={values.videoSettings.uri}
                          type={values.videoSettings.type}
                        />
                      )}
                    </>
                  )}
                  {!!values.videoSettings.assetId &&
                    values.videoSettings.type === 'asset' &&
                    values.videoSettings.uri && (
                      <Grid item xs={12}>
                        <AssetPreview
                          originalFilename={values.videoSettings.originalFilename}
                          assetId={values.videoSettings.assetId}
                          assetService={assetService}
                          casPublicId={casPublicId}
                          type='video'
                          handleDelete={handleDeleteAsset}
                          resource={values.videoSettings.uri}
                        />
                      </Grid>
                    )}
                  {values.videoSettings.type === 'asset' &&
                    !values.videoSettings.uri &&
                    uploads.length === 0 && (
                      <Grid item xs={12}>
                        <Dropzone
                          maxFileSize={5000000000}
                          accept='video/mp4'
                          onDrop={handleDrop}
                          disabled={!!partner?.readonly}
                          supportedFileNames='MP4'
                        />
                      </Grid>
                    )}
                  {values.videoSettings.type === 'asset' && uploads.length > 0 && (
                    <Grid item xs={12}>
                      <UploadIndicator uploads={uploads} handleCancel={handleCancel} />
                    </Grid>
                  )}

                  <Grid xs={12} item>
                    <Field
                      disabled={!!partner?.readonly}
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                        handleTranslation(removeSymbols(e), 'title')
                      }
                      component={FormikTextField}
                      variant='outlined'
                      name={`title.${translationLanguage}`}
                      type='text'
                      multiline
                      rows={8}
                      label={`${formatMessage({
                        id: 'form.photo_and_video.video_upload.title.label',
                        defaultMessage: 'Video description',
                      })} | ${translationLanguage?.toUpperCase() || ''}`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <InfoMessage
                      title={formatMessage({
                        id: 'form.photo_and_video.video_upload.hint.info_video.header',
                        defaultMessage: 'A video completes the partner profile',
                      })}
                      description={formatMessage({
                        id: 'form.photo_and_video.video_upload.hint.info_video.description',
                        defaultMessage:
                          'You have a professional or self-made video of your studio? Perfect! You can either upload it directly from your PC or post the YouTube or Vimeo link here.',
                      })}
                      type='information'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InfoMessage
                      title={formatMessage({
                        id: 'form.photo_and_video.video_upload.hint.info_video.header.copyright',
                        defaultMessage: 'Video rights',
                      })}
                      description={formatMessage({
                        id: 'form.photo_and_video.video_upload.hint.info_video.description.copyright',
                        defaultMessage:
                          'By uploading the image you confirm that you hold the rights to the video.',
                      })}
                      type='information'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </ContentContainer>
      )}
    </Formik>
  );
};
