import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useCurrentPartnerIdQuery } from '@/Queries';
import { getCurrentCheckIns } from '@/Services';
import { CheckInCheckoutEventType } from '@/Types';

const useCurrentCheckins = () => {
  const { data: casPublicId, isLoading: isLoadingCasPublicId } = useCurrentPartnerIdQuery();

  const queryKey = ['currentCheckins', casPublicId];
  const query = useQuery({
    queryKey,
    queryFn: getCurrentCheckIns,
    staleTime: 1000 * 60 * 5,
    retry: 2,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: Boolean(casPublicId) && !isLoadingCasPublicId,
  });

  return {
    ...query,
    data: query.data?.filter(byPartner => byPartner.data.partnerPublicId === casPublicId),
    isLoading: query.isLoading,
    isError: query.isError,
    queryKey,
  };
};

// a simple mutation hook to add a new check-in to the current list of check-ins
export const useCurrentCheckinsMutation = () => {
  const { data: casPublicId } = useCurrentPartnerIdQuery();
  const queryClient = useQueryClient();
  const queryKey = ['currentCheckins', casPublicId];

  const addLiveCheckinMutation = (newItem: CheckInCheckoutEventType) => {
    // Fetch the current list of live check-ins from the cache
    const currentList = queryClient.getQueryData<CheckInCheckoutEventType[]>(queryKey) || [];
    const newCheckInId = newItem.data.checkInId;

    if (newItem.name === 'checkOut') {
      const checkoutIdx = currentList.findIndex(checkin => checkin.data.checkInId === newCheckInId);
      currentList[checkoutIdx] = newItem;

      queryClient.setQueryData(queryKey, currentList);

      return currentList;
    } else {
      const isExistingCheckin = currentList.some(
        checkin => checkin.data.checkInId === newCheckInId,
      );

      if (isExistingCheckin) return currentList;

      const updatedList = [...currentList, newItem];

      queryClient.setQueryData(queryKey, updatedList);

      return updatedList;
    }
  };

  return { mutate: addLiveCheckinMutation };
};

export default useCurrentCheckins;
