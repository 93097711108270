import { Divider, Grid } from '@mui/material';
import { ConnectedFocusError } from 'focus-formik-error';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import { ContentContainer } from '@/App/Shared/ContentContainer/ContentContainer';
import { FormikTextField } from '@/App/Shared/Form/Components/Formik/FormikTextField';
import { FormHeader } from '@/App/Shared/Form/Components/Header/FormHeader';
import { InfoMessage } from '@/Components';
import { DelayModalTypes, useAppContext } from '@/Context';
import { useConfirmDialog } from '@/Hooks/useConfirmDialog';
import useCurrentLanguage from '@/Hooks/useCurrentLanguage';
import useIsFirstTranslation from '@/Hooks/useIsFirstTranslation';
import useReadStorage from '@/Hooks/useReadStorage';
import { useTranslationMutation, useUpdatePartnerDataMutation } from '@/Queries';
import { DescriptionPartial, LanguageType, Partner } from '@/Types';
import { getTargetLanguage, removeSymbols } from '@/Utils';
import { CrazyUseRefCallbackStuffTypes, isDescriptionFormRef } from '@/Utils/formRefTypeguards';
import handleCatchError from '@/Utils/handleCatchError';

export type DescriptionFormProps = {
  casPublicId: Partner['casPublicId'];
  partner: Partner;
  refCallback: (ref: CrazyUseRefCallbackStuffTypes, tabNumber: number) => void;
  handlePrimaryCtaDisabled: (value: boolean) => void;
  setOpenToastMessage: (value: boolean) => void;
};

const useStyles = makeStyles()(() => ({
  divider: {
    marginTop: 80,
    marginBottom: 67,
  },
}));

export const DescriptionForm = ({
  partner,
  refCallback,
  casPublicId,
  handlePrimaryCtaDisabled,
  setOpenToastMessage,
}: DescriptionFormProps) => {
  const {
    dispatch,
    state: { delayModal },
  } = useAppContext();
  const handleConfirmDialog = useConfirmDialog();
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const pageLanguage = useCurrentLanguage();
  const currentLanguage =
    useReadStorage<LanguageType>('vpp_translation_content_language', 'LOCAL_STORAGE') ||
    pageLanguage;
  const targetLanguage = getTargetLanguage(currentLanguage);
  const { isFirstTranslation, setIsFirstTranslation } = useIsFirstTranslation();

  const formikRef = useRef<FormikProps<DescriptionPartial>>(null);
  const [initialState] = useState<DescriptionPartial>({
    description: partner.description,
    notes: partner.notes,
  });

  const { mutateAsync: updatePartnerData } = useUpdatePartnerDataMutation();
  const { mutateAsync: translationMutate } = useTranslationMutation();

  const handleTranslation = useCallback(
    async (textToTranslate: string, field: string) => {
      if (formikRef.current?.setFieldValue && currentLanguage) {
        await translationMutate({
          currentValue: textToTranslate,
          field,

          currentLanguage,

          setFieldValue: formikRef.current?.setFieldValue,
          isFirstTranslation: isFirstTranslation as boolean,
          setIsFirstTranslation,
          targetLanguage,
        });

        handleConfirmDialog(true);
        handlePrimaryCtaDisabled(false);
      }
    },
    [
      setIsFirstTranslation,
      isFirstTranslation,
      translationMutate,
      formikRef,
      targetLanguage,
      currentLanguage,
      handleConfirmDialog,
      handlePrimaryCtaDisabled,
    ],
  );

  useEffect(() => {
    if (isDescriptionFormRef(formikRef) && formikRef.current) {
      refCallback(formikRef, 0);
    }
  }, [refCallback, formikRef]);

  const handleSubmit = async (updatedData: DescriptionPartial) => {
    if (!delayModal.notShowProfileModal) {
      dispatch({ type: DelayModalTypes.SET_OPEN, payload: { open: true } });
    }

    try {
      await updatePartnerData({ casPublicId, updatedData });

      handlePrimaryCtaDisabled(true);
      handleConfirmDialog(false);
      setOpenToastMessage(true);
    } catch (error) {
      handleCatchError(error);
    }
  };

  const handleTranslationChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      formikRef.current?.handleChange(e);
      handlePrimaryCtaDisabled(false);
    },
    [handlePrimaryCtaDisabled],
  );

  return (
    <Formik
      innerRef={formikRef}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialState}
      validate={values => {
        const initialStateStringify = JSON.stringify(initialState);
        const currentStateStringify = JSON.stringify(values);

        if (initialStateStringify !== currentStateStringify) {
          handleConfirmDialog(true);
          handlePrimaryCtaDisabled(false);
        }
      }}
      onSubmit={handleSubmit}>
      {() => (
        <ContentContainer>
          <ConnectedFocusError />
          <Form>
            <Grid container spacing={4}>
              <Grid item container spacing={4}>
                <Grid item xs={12}>
                  <FormHeader
                    title={formatMessage({
                      id: 'form.description_and_opening_hours.header.description',
                      defaultMessage: 'Brief description',
                    })}
                  />
                </Grid>
              </Grid>

              <Grid xs={8} item>
                <Field
                  disabled={partner.readonly}
                  onChange={handleTranslationChange}
                  onBlur={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleTranslation(removeSymbols(e), 'description')
                  }
                  placeholder={formatMessage({
                    id: 'form.description_and_opening_hours.description.placeholder',
                    defaultMessage:
                      'Please write a short text about your facility so that our members can get a first impression.',
                  })}
                  component={FormikTextField}
                  multiline
                  rows={8}
                  variant='outlined'
                  name={`description.${currentLanguage}`}
                  type='text'
                  label={`${formatMessage({
                    id: 'form.description_and_opening_hours.header.description',
                    defaultMessage: 'Brief description',
                  })} | ${currentLanguage.toUpperCase()}`}
                />
              </Grid>
              <Grid xs={4} item>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <InfoMessage
                      title={formatMessage({
                        id: 'form.description_and_opening_hours.hint.description.header',
                        defaultMessage: 'Describe your offer',
                      })}
                      description={formatMessage({
                        id: 'form.description_and_opening_hours.hint.description.description',
                        defaultMessage:
                          'Write a short text about your facility and give Hansefit members a first impression of what they can expect from you.',
                      })}
                      type='information'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={0}>
              <Grid item xs={8}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item container spacing={4}>
                <Grid item xs={12}>
                  <FormHeader
                    title={formatMessage({
                      id: 'form.description_and_opening_hours.header.notes',
                      defaultMessage: 'Current information',
                    })}
                  />
                </Grid>
              </Grid>

              <Grid xs={8} item>
                <Field
                  disabled
                  onBlur={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    handleTranslation(removeSymbols(e), 'notes')
                  }
                  placeholder={formatMessage({
                    id: 'form.description_and_opening_hours.notes.placeholder',
                    defaultMessage: 'Inform us in case of data changes ',
                  })}
                  component={FormikTextField}
                  multiline
                  rows={4}
                  variant='outlined'
                  name={`notes.${currentLanguage}`}
                  type='text'
                  label={`${formatMessage({
                    id: 'form.description_and_opening_hours.notes',
                    defaultMessage: 'News',
                  })} | ${currentLanguage.toUpperCase()}`}
                />
              </Grid>
              <Grid xs={4} item>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <InfoMessage
                      title={formatMessage({
                        id: 'form.description_and_opening_hours.hint.notes.header',
                        defaultMessage: 'Inform us in case of data changes',
                      })}
                      description={formatMessage({
                        id: 'form.description_and_opening_hours.hint.notes.description',
                        defaultMessage: 'Inform us in case of data changes ',
                      })}
                      type='information'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </ContentContainer>
      )}
    </Formik>
  );
};
