import { Box, Card, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import { CategoryFAQ } from '@/Types';

import { GridWrapper } from './Faq.styles';

type FAQCardProps = {
  data: CategoryFAQ;
};

export const FAQCard = ({ data }: FAQCardProps) => {
  const { categoryName, categoryDescription, faqs, id } = data;

  const currentLanguage = useCurrentLanguage();
  const { formatMessage } = useIntl();
  const FAQ_Questions = faqs?.slice(0, 3) || [];

  return (
    <GridWrapper item xs={6} display='flex'>
      <Card variant='outlined' className='card_wrapper'>
        <Box>
          <Typography className='card_title' variant='h4'>
            {categoryName}
          </Typography>
          <Typography className='card_text' variant='body1'>
            {categoryDescription}
          </Typography>
          {FAQ_Questions.map(faq => (
            <Link
              key={faq.id}
              className='card_link'
              to={`/${currentLanguage}/unterstutzung?faq=${id}&article=${faq.id}`}>
              {faq.question}
            </Link>
          ))}
        </Box>
        {FAQ_Questions.length > 1 && (
          <Link className='link_all' to={`/${currentLanguage}/unterstutzung?faq=${id}`}>
            {formatMessage({
              id: 'support.button.learn_more',
              defaultMessage: 'Show all articles',
            })}
          </Link>
        )}
      </Card>
    </GridWrapper>
  );
};
