import '@/Static/Fonts/stylesheet.css';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license';
import * as Sentry from '@sentry/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Amplify } from 'aws-amplify';
import { useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from 'react-router-dom';

import { ModalManager, SpinnerLoader } from '@/Components';
import { useTranslateQuery } from '@/Queries';
import { isLanguageType, LanguageType } from '@/Types';
import { Authenticate } from '@/Views/Authentication/Components/Authenticate';

import { RouteLeavingGuard } from './App/Infrastructure/RouteLeavingGuard/RouteLeavingGuard';
import ToastContainer from './App/Shared/ToastContainer';
import HansefitTheme from './App/Theme/Theme';
import awsConfig from './aws-exports';
import Routes from './Routes';

const API_TRANSLATE = process.env.REACT_APP_TRANSLATE;

LicenseInfo.setLicenseKey(
  'e3ab5516e4c537760d54e859af81277fTz04NzczOCxFPTE3NDM3NTUzNTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);
Amplify.configure(awsConfig);

Sentry.init({
  dsn: 'https://ba09733a60024e9724a19cbe381b1a0f@o4504718314831872.ingest.sentry.io/4505709723648000',
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  enableTracing: true,
  tracesSampleRate: 0.5,
  tracePropagationTargets: ['localhost', 'pimcore.*.hansefit.de', 'amazonaws.com'],

  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});

const App = () => {
  const locationLanguage = window.location.pathname.split('/')?.[1];
  const language: LanguageType = useMemo(() => {
    if (
      locationLanguage &&
      window.location.pathname.split('/').length > 2 &&
      isLanguageType(locationLanguage)
    ) {
      return locationLanguage;
    }

    return 'de';
  }, [locationLanguage]);

  const isRemoteEnv = API_TRANSLATE === 'remote' && window.location.hostname !== 'localhost';

  const { data: terms, isLoading } = useTranslateQuery({ isRemoteEnv, language });

  return (
    <>
      <ThemeProvider theme={HansefitTheme}>
        <CssBaseline />

        {(!terms || isLoading) && <SpinnerLoader isFullScreen />}
        <IntlProvider locale={language} messages={terms} onError={() => {}}>
          <Router>
            <ModalManager />
            <Authenticate />
            <Routes />
            <RouteLeavingGuard />
          </Router>
          <ToastContainer />
          {/* <CookieConsent /> */}
        </IntlProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

export default App;
