import {
  GridColDef,
  GridColumnsState,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import csvDownload from 'json-to-csv-export';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';

type ExportedRow = Record<string, string | number>;

const exportHelper = (
  data: GridValidRowModel[],
  colDef: GridColDef[],
  gridColumnState: GridColumnsState,
  type: 'CSV' | 'EXCEL' | 'PDF',
  sortOrder?: GridSortModel,
) => {
  if (!data?.length) return;
  const sortedData = [...data];
  const columnsFieldOrderState = gridColumnState.orderedFields || colDef.map(col => col.field);

  const dataToExport: ExportedRow[] = [];
  sortedData.sort((a, b) => {
    if (!sortOrder?.[0]) return 0;

    const sortColumn = sortOrder[0];
    const sortDirection = sortColumn.sort;
    const sortField = sortColumn.field;

    if (sortDirection === 'asc') {
      return a[sortField] > b[sortField] ? 1 : -1;
    } else {
      return a[sortField] < b[sortField] ? 1 : -1;
    }
  });

  sortedData.forEach(row => {
    const exportRow: ExportedRow = {};

    columnsFieldOrderState.forEach(fieldName => {
      const column = colDef.find(col => col.field === fieldName) as GridColDef;

      if (!column?.headerName) return;
      if (gridColumnState.columnVisibilityModel?.[column.field] === false) return;

      let rowValue =
        row[column.field] !== null || row[column.field] !== undefined || row[column.field] !== ''
          ? row[column.field]
          : '';

      if (typeof column.valueGetter === 'function' && rowValue) {
        // call table getter function to avoid redundant formatter

        rowValue =
          row[column.field] &&
          column.valueGetter(row[column?.field] as never, row, column, undefined as never);
      }
      if (typeof column.valueFormatter === 'function' && rowValue) {
        // call table formatter function to avoid redundant formatter

        rowValue = column.valueFormatter(
          row[column.field] as never,
          row,
          column,
          undefined as never,
        );
      }
      exportRow[column.headerName] = rowValue;
    });

    dataToExport.push(exportRow);
  });

  if (type === 'EXCEL') {
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    XLSX.writeFile(workbook, 'export.xlsx');
  }

  if (type === 'CSV') {
    csvDownload({
      data: dataToExport,
      filename: 'export',
      delimiter: ';',
    });
  }

  if (type === 'PDF') {
    const doc = new jsPDF();
    autoTable(doc, {
      head: [Object.keys(dataToExport[0])],
      body: dataToExport.map(row => Object.values(row)),
      theme: 'striped',
    });
    doc.save('export.pdf');
  }
};

export default exportHelper;
