import { Divider } from '@mui/material';
import { FormikHelpers, FormikProps } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { CtaFooter } from '@/App/Shared/Footer/CtaFooter';
import { FormSnackbar } from '@/App/Shared/Notification/Components/FormSnackbar';
import { CurrentUserForm, Header } from '@/Components';
import { useAppContext } from '@/Context';
import useCurrentUserBreadcrumbs from '@/Hooks/Breadcrumbs/useCurrentUserBreadcrumbs';
import useCurrentUserAttr from '@/Hooks/useCurrentUserAttr';
import { BtnLabelsTypes, getButtonLabel } from '@/Mappers';
import { useCurrentPartnerIdQuery, useCurrentUserRoleQuery, useGetPartnerQuery } from '@/Queries';
import {
  DeeplServiceFactory,
  EventsServiceFactory,
  PartnerCoursesServiceFactory,
  UsersServiceFactory,
} from '@/Services';
import { CurrentUserFormik, Role } from '@/Types';

const CurrentUser = () => {
  const intl = useIntl();
  const { data: partner } = useGetPartnerQuery();
  const { dispatch } = useAppContext();
  const [openToastMessage, setOpenToastMessage] = useState(false);
  const { data: casPublicId } = useCurrentPartnerIdQuery();
  const [isUserAttrDirty, setIsUserAttrDirty] = useState(false);
  const [currentUserFormRef, setCurrentUserFormRef] = useState<FormikHelpers<CurrentUserFormik>>();
  const partnerCoursesService = new PartnerCoursesServiceFactory().getInstance(dispatch);
  const eventsService = new EventsServiceFactory().getInstance(dispatch);
  const usersService = new UsersServiceFactory().getInstance(dispatch);
  const deeplService = new DeeplServiceFactory().getInstance(dispatch);
  const { data: role } = useCurrentUserRoleQuery();
  const { data: userAttr, refetch: refetchUserAttr } = useCurrentUserAttr();

  const primaryCtaTitle = getButtonLabel(intl, BtnLabelsTypes.SAVE_CHANGES);
  const [primaryCtaDisabled, setPrimaryCtaDisabled] = useState(true);
  const primaryCtaVariant = 'contained';

  const handleIsUserAttrDirty = useCallback((value: boolean) => {
    setIsUserAttrDirty(value);
  }, []);

  const handleToastMessage = useCallback((value: boolean) => {
    setOpenToastMessage(value);
  }, []);

  const receiveRef = useCallback(
    (ref: React.RefObject<FormikProps<CurrentUserFormik> | undefined>) => {
      if (ref.current) setCurrentUserFormRef(ref.current);
    },
    [],
  );

  const handlePrimaryCta = useCallback(() => {
    if (currentUserFormRef) {
      return currentUserFormRef.submitForm();
    }
  }, [currentUserFormRef]);
  const currentBreadcrumbs = useCurrentUserBreadcrumbs();

  const refetchDirtyUserAttr = useCallback(async () => {
    await refetchUserAttr();
    setIsUserAttrDirty(false);
  }, [refetchUserAttr]);

  useEffect(() => {
    if (isUserAttrDirty) {
      refetchDirtyUserAttr();
    }
  }, [isUserAttrDirty, refetchDirtyUserAttr]);

  return (
    <>
      <Header
        showLanguageSwitcher={false}
        callToActions={
          role === Role.ADMIN
            ? {
                primary: {
                  title: primaryCtaTitle,
                  callback: handlePrimaryCta,
                  disabled: primaryCtaDisabled,
                  variant: primaryCtaVariant,
                },
              }
            : {}
        }
        header={intl.formatMessage({
          id: 'view.current_user.header',
          defaultMessage: 'My user account',
        })}
        breadcrumbs={currentBreadcrumbs}
      />
      <FormSnackbar
        openToastMessage={openToastMessage}
        handleCloseToastMessage={() => handleToastMessage(false)}
      />
      {casPublicId &&
        role &&
        userAttr &&
        partnerCoursesService &&
        eventsService &&
        deeplService &&
        usersService &&
        partner && (
          <>
            {role !== Role.ADMIN && <Divider sx={{ marginTop: '16px', border: 0 }} />}
            <CurrentUserForm
              casPublicId={casPublicId}
              refCallback={receiveRef}
              userAttr={userAttr}
              handleIsUserAttrDirty={handleIsUserAttrDirty}
              userRole={role}
              handleToastMessage={handleToastMessage}
              setPrimaryCtaDisabled={setPrimaryCtaDisabled}
              usersService={usersService}
              eventsService={eventsService}
            />
          </>
        )}
      <CtaFooter
        callToActions={
          role === Role.ADMIN
            ? {
                primary: {
                  title: primaryCtaTitle,
                  callback: handlePrimaryCta,
                  disabled: primaryCtaDisabled,
                  variant: primaryCtaVariant,
                },
              }
            : {}
        }
      />
    </>
  );
};

export default CurrentUser;
