import 'moment/locale/de';

import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useFormikContext } from 'formik';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { CustomDataGrid } from '@/App/Shared/DataGridTable/Table/DataGridTable';
import { NoResultsOverlay } from '@/App/Shared/DataGridTable/Table/NoResultsOverlay';
import { NoRowsOverlay } from '@/App/Shared/DataGridTable/Table/NoRowsOverlay';
import { useAppContext } from '@/Context';
import { useCurrentLanguage } from '@/Hooks/useCurrentLanguage';
import { EVENT_LIST_VIEW, useEventsTableView } from '@/Hooks/useEventsTableView';
import { useTableRows } from '@/Hooks/useTableRows';
import { getAddEventPathname, getEventTableColumns, getNewCoursePathname } from '@/Mappers';
import { EventsService, EventsServiceFactory } from '@/Services';
import { EventList, EventListItem, EventsData, OptionalTableColumnView } from '@/Types';

import { Bookings } from '../Modals/Bookings';
import { EvenListToolbar } from '../Toolbar/Toolbar';

type EventsTableProps = {
  displayColumns: OptionalTableColumnView;

  handleEditEventsModal?: (eventIds: string[]) => void;
  handleEditEventsStreamDataModal?: (eventIds: string[]) => void;
  handlePublishEventsModal?: (eventIds: string[]) => void;
  handleCancelEventsModal?: (eventIds: string[]) => void;
  handleUpdateEvents: () => void;
  isLoading?: boolean;
  paginationState: EventsData['pagination'];
  setPaginationState: Dispatch<SetStateAction<EventsData['pagination']>>;
  handleSelectedRows: (ids: GridRowSelectionModel) => void;
  selectedRows: GridRowSelectionModel;
};

export const EventsTable = ({
  displayColumns,
  handleEditEventsModal = () => {},
  handleEditEventsStreamDataModal = () => {},
  handlePublishEventsModal = () => {},
  handleCancelEventsModal = () => {},
  handleUpdateEvents,

  isLoading,
  paginationState,
  setPaginationState,
  handleSelectedRows,
  selectedRows,
}: EventsTableProps) => {
  const view = useEventsTableView();
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams<{ courseId: string }>();
  const currentLanguage = useCurrentLanguage();
  const { values, setFieldValue } = useFormikContext<EventList>();
  const { dispatch } = useAppContext();

  const [actionRow, setActionRow] = useState<EventListItem | null>(null);
  const [bookingListModalOpen, setBookingListModalOpen] = useState<boolean>(false);
  const eventsService = useMemo(() => new EventsServiceFactory().getInstance(dispatch), [dispatch]);

  const renderToolbar = () => {
    if (view === EVENT_LIST_VIEW.DASHBOARD) return null;

    return (
      <EvenListToolbar
        handleEditEventsStreamDataModal={handleEditEventsStreamDataModal}
        handleEditEventsModal={handleEditEventsModal}
        handlePublishEventsModal={handlePublishEventsModal}
        handleCancelEventsModal={handleCancelEventsModal}
        handleNewEventsForm={handleNewEventsForm}
        clearSelection={clearSelection}
        showAddNew={view === EVENT_LIST_VIEW.EVENTS}
        isDisabled={!rows.length}
      />
    );
  };

  const rows = useTableRows({
    uniqueIdKey: 'eventId',
    data: values?.eventsData?.result || [],
  });

  const handleNewCourseForm = useCallback(() => {
    navigate(getNewCoursePathname(currentLanguage));
  }, [navigate, currentLanguage]);

  const handleNewEventsForm = useCallback(() => {
    navigate(getAddEventPathname(currentLanguage, parseInt(params.courseId!)));
  }, [navigate, currentLanguage, params.courseId]);

  const handleCloseBookingListModal = () => {
    setBookingListModalOpen(false);
    setActionRow(null);
  };

  const handlers = {
    handleEditEventsModal,
    handlePublishEventsModal,
    handleCancelEventsModal,
    handleEditEventsStreamDataModal,
    setBookingListModalOpen,
    setActionRow,
    handleUpdateEvents,
  };

  const columns = getEventTableColumns(displayColumns, intl, view, handlers);

  const clearSelection = () => {
    setFieldValue('checkedEvents', []);
    handleSelectedRows([]);
  };

  return (
    <>
      <CustomDataGrid
        initialState={{
          pinnedColumns: { right: ['actions'] },
          pagination: {
            paginationModel: {
              pageSize: paginationState.itemsPerPage,
              page: paginationState.currentPage - 1,
            },
          },
        }}
        columns={columns}
        rows={rows}
        rowHeight={66}
        hideColumns={values.eventsData.result.length === 0}
        checkboxSelection
        pagination
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        loading={isLoading}
        autoHeight
        onPaginationModelChange={pagination => {
          setPaginationState(prev => ({
            ...prev,
            currentPage: pagination.page + 1,
            itemsPerPage: pagination.pageSize,
          }));
        }}
        rowCount={paginationState.totalCount}
        paginationMode='server'
        slots={{
          toolbar: renderToolbar,
          noRowsOverlay: () => (
            <NoRowsOverlay
              header={
                !(view === EVENT_LIST_VIEW.SCHEDULE || EVENT_LIST_VIEW.EVENTS)
                  ? intl.formatMessage({
                      id: 'view.courses_and_events.events.table.empty_header_courses',
                      defaultMessage: 'You did not create courses yet. Start now!',
                    })
                  : intl.formatMessage({
                      id: 'view.courses_and_events.events.table.empty_header_events',
                      defaultMessage: 'You did not create events yet. Start now!',
                    })
              }
              subHeader={intl.formatMessage({
                id: 'view.courses_and_events.events.table.empty_sub_header',
                defaultMessage: 'You can only create online courses over this portal.',
              })}
              ctaDisabled
              ctaTitle={
                !(view === EVENT_LIST_VIEW.SCHEDULE || EVENT_LIST_VIEW.EVENTS)
                  ? intl.formatMessage({
                      id: 'view.courses_and_events.events.table.cta.new_course',
                      defaultMessage: 'Create new course',
                    })
                  : intl.formatMessage({
                      id: 'view.courses_and_events.events.table.cta.empty',
                      defaultMessage: 'Create new course',
                    })
              }
              ctaHandler={
                !(view === EVENT_LIST_VIEW.SCHEDULE || EVENT_LIST_VIEW.EVENTS)
                  ? handleNewCourseForm
                  : handleNewEventsForm
              }
            />
          ),
          noResultsOverlay: () => (
            <NoResultsOverlay
              header={intl.formatMessage({
                id: 'view.courses_and_events.events.table.empty_filter_header',
                defaultMessage: 'No Events found',
              })}
              subHeader={intl.formatMessage({
                id: 'view.courses_and_events.events.table.empty_filter_sub_header',
                defaultMessage: 'Please change your search criteria and try again.',
              })}
            />
          ),
        }}
        onRowSelectionModelChange={(newSelection: GridRowSelectionModel) => {
          const idsString = newSelection.map(item => item.toString());
          setFieldValue('checkedEvents', idsString);
          handleSelectedRows(newSelection);
        }}
        rowSelectionModel={selectedRows}
        isRowSelectable={params => !EventsService.isCanceled(params.row as EventListItem)}
      />
      {bookingListModalOpen && actionRow && (
        <Bookings
          isOpen={bookingListModalOpen}
          events={[actionRow]}
          eventIds={[actionRow.eventId.toString()]}
          eventsService={eventsService}
          handleClose={handleCloseBookingListModal}
        />
      )}
    </>
  );
};
