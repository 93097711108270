import { Box, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { CustomTable } from '@/App/Shared/Table/CustomTable';
import { Dashboard } from '@/Mappers';
import { StatsData } from '@/Types';

import { useTopStyles } from './TopAndStats.styles';

type TopProps = {
  stats: StatsData;
};

export const Top = ({ stats: { top3Events } }: TopProps) => {
  const { formatMessage } = useIntl();
  const dashboardService = new Dashboard();
  const { classes } = useTopStyles();

  return (
    <Box className={classes.root}>
      <Typography variant='body1' className={classes.header}>
        {formatMessage({
          id: 'view.dashboard.stats.top.header',
          defaultMessage: 'Your top 3 ',
        }).toUpperCase()}
      </Typography>
      <Box>
        <CustomTable
          tableBody={
            <TableBody>
              {top3Events.map((item, idx) => (
                <TableRow key={item.courseName + idx}>
                  <TableCell className={classes.tableCell}>
                    <Box
                      className={clsx(
                        {
                          [classes.tableCellContainerFirstPos]: idx === 0,
                          [classes.tableCellContainerSecondPos]: idx === 1,
                          [classes.tableCellContainerThirdPos]: idx === 2,
                        },
                        classes.tableCellContainer,
                        classes.tableCellContainerFirst,
                        classes.tableCellContainerRound,
                      )}>
                      {idx + 1}
                    </Box>
                  </TableCell>
                  <TableCell className={clsx(classes.tableCell, classes.tableCellFirstPos)}>
                    <Box
                      className={clsx(
                        classes.tableCellContainer,
                        classes.tableCellContainerFirstRound,
                        classes.tableCellContainerBorder,
                      )}>
                      {item.courseName}
                    </Box>
                  </TableCell>
                  <TableCell className={clsx(classes.tableCell, classes.tableCellSecondPos)}>
                    <Box
                      className={clsx(
                        classes.tableCellContainer,
                        classes.tableCellContainerBorder,
                      )}>
                      {dashboardService.getFormattedDateTime(item.eventDate, item.eventTime)}
                    </Box>
                  </TableCell>
                  <TableCell className={clsx(classes.tableCell, classes.tableCellThirdPos)}>
                    <Box
                      className={clsx(
                        classes.tableCellContainer,
                        classes.tableCellContainerLastRound,
                        classes.participantsContainer,
                      )}>
                      <Box>{item.bookings}</Box>

                      <Box className={classes.participantsText}>
                        &nbsp;
                        {formatMessage({
                          id: 'view.dashboard.stats.top.participants',
                          defaultMessage: 'Participant',
                        })}
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          }
        />
      </Box>
    </Box>
  );
};
